import React, { useState } from 'react';
import classes from './HenrysRentalSnippet.module.css';
import CONST from '../../../constants/constants';
import { MdDelete } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import Axios from 'axios';

export default function HenrysRentalSnippet(props){

    const imagesArray = props.henrysImages;
    
    const [henrysRentalSnippet, setHenrysRentalSnippet] = useState({
        frontImage: true
    });

    const [transferModal, setTransferModal] = useState({
        shown: false,
        id: "",
    });

    const handleImageViewHandler = function(){
        setHenrysRentalSnippet({
            frontImage: !henrysRentalSnippet.frontImage
        })
    }

    const familyMemberChangeHandler = function(e){
        if(e.target.value && e.target.value !== "transfer"){
            setTransferModal({
                id: e.target.value,
                shown: true
            })
        }
    }

    const stopTransferRemoval = function(){
        setTransferModal({
            id: "",
            shown: false
        })
    }

    const confirmTransferRemoval = function(){
        Axios.defaults.withCredentials = true;
        Axios.post(CONST.BASE_URL + '/api/transfer-henrys-rental-media', {"media_id": props.id, "user_id": transferModal.id}).then(response =>{
            setTransferModal({
                id: "",
                shown: false
            })
            props.setMediaHandler();
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

    let shownImage = <img onClick={handleImageViewHandler} src={CONST.BASE_URL+"/storage/images/henrys-rental-images/" + props.image_front + ".webp"} className={classes.imageFront} alt="" />
    let deleteButtonShown = false;
    let confirmTransferModal = false;
    let transferFunction = "";
    let whoHasIt = props.who_has_it;

    // Show Modal only when a user has been selected to transfer to
    if(transferModal.shown){
        confirmTransferModal = 
        <div className={classes.confirmTransferModal}>
            <div className={classes.confirmTransferModalControllsContainter}>
                <FaCheck className={classes.tick} onClick={confirmTransferRemoval} />
                <img className={classes.modalImage} src={CONST.BASE_URL+"/storage/images/henrys-images/" + imagesArray[randomIntFromInterval(0, props.henrysImages.length - 1)].name + ".webp"} alt="henrys media choose logo"/>
                <ImCross className={classes.cross} onClick={stopTransferRemoval} />
            </div>
            <h2>Confirm Transfer</h2>
        </div>
    }

    if(!henrysRentalSnippet.frontImage){
        shownImage = <img onClick={handleImageViewHandler} src={CONST.BASE_URL+"/storage/images/henrys-rental-images/" + props.image_back + ".webp"} className={classes.imageBack} alt="" />
    }

    // Check if user is an admin user
    if(props.admin){
        deleteButtonShown = <MdDelete onClick={props.confirmDeleteHandler.bind(this, props.id)}/>
    }

    // Check who has it
    if(JSON.parse(localStorage.getItem("user")).id === props.who_has_it_id){
            transferFunction =  <select
                                    className={classes.transferInput}
                                    name="familyMember"
                                    onChange={familyMemberChangeHandler}
                                >
                                    <option name="familyMember" value="transfer">Transfer to?</option>
                                    {props.familyMembers.map((familyMember, i) =>
                                        <option key={familyMember.error_id} name="familyMember" value={familyMember.error_id}>{familyMember.error_maker}</option>
                                    )}
                                </select>
            whoHasIt = "You";
    }

    return(
        <div className={classes.henrysRentalSnippet}>
            {confirmTransferModal}
            <div className={classes.mediaInfoContainer}>
                <h3 className={classes.title} onClick={props.titleModalHandler.bind(this, props.id)}>{props.title}</h3>
                <div className={classes.categoriesContainer}>
                    {props.henrys_rental_media_categories.map((henrys_rental_media_category, i) =>
                        <p key={henrys_rental_media_category.id}>{henrys_rental_media_category.category_name}</p>
                    )}
                </div>
                <p>{props.media_type}</p>
                <div className={classes.transferContainer}>
                    <p>Who has it: {whoHasIt}</p>
                    {transferFunction}
                </div>
            </div>
            <div className={classes.imagesContainer}>
                {shownImage}
            </div>
            <div className={classes.infoContainer}>
                <h5>Transferred: {props.times_transferred}</h5>
                <h5>Added By: {props.henrys_rental_media_user}</h5>
                {deleteButtonShown}
            </div>
        </div>
    )
}