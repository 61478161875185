import React, { Component } from 'react';
import classes from './NavigationItems.module.css';
import NavigationItem from '../NavigationItem/NavigationItem';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import CONST from '../../../../constants/constants';

class NavigationItems extends Component {

    state = {
        redirectOnSuccess: "",
        loggedInIcon: <NavigationItem navClicked={this.props.clicked} link="/login" linkName="login"/>
    }

    logoutHandler = (event) => {
        event.preventDefault();
        if(!this.props.sideDrawerVisible){
            this.props.clicked();
        }
        localStorage.clear();
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/logout');
        this.setState({
            loggedInIcon: <NavigationItem navClicked={this.props.clicked} link="/login" linkName="login"/>,
            redirectOnSuccess: <Redirect to={{
                                    pathname: "/login",
                                    state: { fromRedirect: "You have been successfully logged out" }
                                    }}                  
                                />
        })
    }

    componentDidMount(){
        if(JSON.parse(localStorage.getItem("user"))){
            this.setState({
                userLoggedIn: JSON.parse(localStorage.getItem("user")),
                loggedInIcon: <NavigationItem link="" navClicked={this.logoutHandler} linkName="logout"/>
            })   
        }
    }
    
    render(){
        let newBogeEntryLink = "";
        let myProfileLink = "";
        let unVotedBogeesLink = "";
        if(JSON.parse(localStorage.getItem("user"))){
            newBogeEntryLink = <NavigationItem navClicked={this.props.clicked} link="/new-boge-entry" linkName="new&nbsp;entry"/>;
            myProfileLink = <NavigationItem navClicked={this.props.clicked} link="/my-profile" linkName="profile"/>;
            unVotedBogeesLink = <NavigationItem navClicked={this.props.clicked} link="/not-voted-boge-entries" linkName="unvoted"/>
        }
       
        return(
            <div className={classes.NavigationItems}>
                {this.state.redirectOnSuccess}
                <NavigationItem navClicked={this.props.clicked} reset={true} link="/" linkName="home"/>
                {unVotedBogeesLink}
                {newBogeEntryLink}
                {myProfileLink}
                <NavigationItem navClicked={this.props.clicked} link="/quiz-landing-page" linkName="quiz"/>
                {this.state.loggedInIcon}
            </div>
        )
    }
}

export default NavigationItems
