import React, { useEffect, useState } from 'react';
import classes from './HenrysRental.module.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CONST from '../../constants/constants';
import Axios from 'axios';
import HenrysRentalSnippet from './HenrysRentalSnippet/HenrysRentalSnippet';
import { FaRegPlusSquare } from "react-icons/fa";
import ConfirmDelete from '../../components/Ui/ConfirmDelete/ConfirmDelete';
import Autocomplete from '../../components/Ui/AutoComplete/KnownCulprits';
import FlashMessage from '../../components/Ui/FlashMessage/FlashMessage';
import HenryRentalTitleModal from './HenryRentalTitleModal/HenryRentalTitleModal';

const user = JSON.parse(localStorage.getItem("user"));

export default function HenrysRental(props){

    const [henryRentalMedia, setHenryRentalMedia] = useState({
        userName: "",
        media: [],
        mediaArray: [],
        users: [],
        familyMembers: [],
        message: "",
        henrysImages: []
    });

    const [henryRentalMediaChangeable, setHenryRentalMediaChangeable] = useState({
        changeableMediaArray: [],
        changeableMedia: []
    });

    const [henryRentalMediaMessage, setHenryRentalMediaMessage] = useState({
        message: ""
    });

    const [henryRentalMediaConfirmDelete, setHenryRentalMediaConfirmDelete] = useState({
        shown: false
    });

    const [addUserToFamilyHandlerConfirm, setAddUserToFamilyHandlerConfirm] = useState({
        shown: false,
        id: ""
    });

    const [henryRentalTitleModal, setHenryRentalTitleModal] = useState({
        shown: false,
        id: ""
    });

    const [inputStyle, setInputStyle] = useState("");

    const listenScrollEvent = () => {
        window.scrollY > 60 ? setInputStyle("scrolling") : setInputStyle("");
      };

    useEffect(() => {
        setMediaHandler();
        window.addEventListener("scroll", listenScrollEvent);
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
          };
    }, []);

    const getData = function(val, id){
        searchMediaHandler(val, id)
    }

    const addUserToFamilyHandlerConfirmHandler = function(val, id){
        setAddUserToFamilyHandlerConfirm({
            shown: !addUserToFamilyHandlerConfirm.shown,
            id: id
        })
    }

    const addUserToFamilyHandler = function(){
        Axios.defaults.withCredentials = true;
        Axios.post(CONST.BASE_URL + '/api/henrys-rental-media-add-family-member/' + addUserToFamilyHandlerConfirm.id).then(response =>{
            setAddUserToFamilyHandlerConfirm({
                shown: !addUserToFamilyHandlerConfirm.shown,
                id: ""
            })
            setHenryRentalMediaMessage({
                message: <FlashMessage message={response.data.message} duration={5000}/>
            })
        })
    }

    

    const searchMediaHandler = function(val, id){
        if(val.length === 0){
            setHenryRentalMediaChangeable({
                changeableMediaArray: henryRentalMedia.mediaArray,
                changeableMedia: henryRentalMedia.media
            })
        } else {
            const items = henryRentalMedia.media.filter(
                item =>
                    item.title.toLowerCase().includes(val.toLowerCase())
              );
            setHenryRentalMediaChangeable({
                changeableMediaArray: henryRentalMedia.mediaArray,
                changeableMedia: items
            })
        }
    }

    const setMediaHandler = function(){
        Axios.defaults.withCredentials = true;
        Axios.get(CONST.BASE_URL + '/api/get-henrys-rental-media').then(response =>{
            setHenryRentalMediaChangeable({
                changeableMediaArray: response.data.mediaArray,
                changeableMedia: response.data.media,
            })
            setHenryRentalMedia({
                userName: response.data.message,
                media: response.data.media,
                mediaArray: response.data.mediaArray,
                users: response.data.users,
                familyMembers: response.data.familyMembers,
                message: response.data.message,
                henrysImages: response.data.henrysImages
            })
        })
    }

    const confirmDeleteHandler = function(id){
        setHenryRentalMediaConfirmDelete({
            shown: !henryRentalMediaConfirmDelete.shown,
            id: id
        })
    }

    const deleteMediaHandler = function(){
        Axios.defaults.withCredentials = true;
        Axios.delete(CONST.BASE_URL + '/api/delete-henrys-rental-media/' + henryRentalMediaConfirmDelete.id).then(response =>{
            
        })
        setMediaHandler();
        confirmDeleteHandler("");
    }

    const titleModalHandler = function(id){
        setHenryRentalTitleModal({
            shown: true,
            id: id
        })
    }

    const closeTitleModalHandler = function(){
        setHenryRentalTitleModal({
            shown: false,
            id: ""
        })
    }

    let confirmDelete = "";

    if(henryRentalMediaConfirmDelete.shown){
        confirmDelete = <ConfirmDelete delete={deleteMediaHandler} clicked={confirmDeleteHandler}/>;
    }

    if(addUserToFamilyHandlerConfirm.shown){
        confirmDelete = <ConfirmDelete delete={addUserToFamilyHandler} clicked={addUserToFamilyHandlerConfirmHandler}/>;
    }
    
    let addFamilyMemberInput = "";
    let addFamilyMemberAddCategory = "";
    let addImage = "";

    if(user.admin){

        addFamilyMemberInput = <Autocomplete
                                    familyMemberSearch={true}
                                    placeHolder={"Add New Family Member"}
                                    suggestions={henryRentalMedia.users}
                                    sendData={getData}
                                    setUser={addUserToFamilyHandlerConfirmHandler}
                                />
        
        addFamilyMemberAddCategory =    <Link className={"main-button " + classes.controlsButton} to="/henrys-rental-add-category/">
                                            <FaRegPlusSquare /><h3> Category</h3>
                                        </Link>
                                        
        addImage =    <Link className={"main-button " + classes.controlsButton} to="/henrys-rental-add-images/">
                                            <FaRegPlusSquare /><h3> Image</h3>
                                        </Link>

    }

    let redirectMessage = "";

    if (props.location.state !== undefined) {
        redirectMessage = <FlashMessage message={props.location.state.fromRedirect} duration={5000}/>
    }

    let henryRentalTitleModalHolder = "";

    if(henryRentalTitleModal.shown){
        henryRentalTitleModalHolder = 
        <HenryRentalTitleModal 
            closeTitleModalHandler={closeTitleModalHandler}
            id={henryRentalTitleModal.id}
            henrysImages={henryRentalMedia.henrysImages}
        />
    }
    
    return(
        <div className={classes.HenrysRental}>
            {confirmDelete}
            {henryRentalTitleModalHolder}
            <div className={classes.controlsContainer}>
                <h2>{henryRentalMedia.message}</h2>
                <h4>{henryRentalMedia.media.length} titles in library</h4>
                <Autocomplete
                    inputStyle={inputStyle}
                    henrysMediaSearch={true}
                    placeHolder={"Search Henry's Media"}
                    suggestions={henryRentalMedia.mediaArray}
                    sendData={getData}
                    setUser={searchMediaHandler}
                />
                <div className={classes.controlsButtons}>
                    {addFamilyMemberAddCategory}
                    {addImage}
                    <Link className={"main-button"} to="/henrys-rental-add" >
                        <FaRegPlusSquare /><h3> Media</h3>
                    </Link>
                </div>
                {addFamilyMemberInput}
                {redirectMessage}
                {henryRentalMediaMessage.message}
                {henryRentalMediaChangeable.changeableMedia.map((henryRentalSingleMedia) =>
                <HenrysRentalSnippet
                    key={henryRentalSingleMedia.id}
                    id={henryRentalSingleMedia.id}
                    title={henryRentalSingleMedia.title}
                    henrys_rental_media_categories={henryRentalSingleMedia.henrys_rental_media_categories}
                    henrys_rental_media_user={henryRentalSingleMedia.henrys_rental_media_user.name}
                    image_back={henryRentalSingleMedia.image_back}
                    image_front={henryRentalSingleMedia.image_front}
                    media_type={henryRentalSingleMedia.media_type}
                    who_has_it_id={henryRentalSingleMedia.who_has_it_user.id}
                    who_has_it={henryRentalSingleMedia.who_has_it_user.name}
                    times_transferred={henryRentalSingleMedia.times_transferred}
                    confirmDeleteHandler={confirmDeleteHandler}
                    admin={user.admin}
                    familyMembers={henryRentalMedia.familyMembers}
                    setMediaHandler={setMediaHandler}
                    henrysImages={henryRentalMedia.henrysImages}
                    titleModalHandler={titleModalHandler}
                />   
            )}
            </div>
        </div>
    )
}