import Axios from 'axios';
import React, { Component } from 'react';
import classes from './Home.module.css';
import BogeEntrySnippet from '../../components/BogeEntrySnippet/BogeEntrySnippet';
import CONST from '../../constants/constants';
import FUNCTIONS from '../../functions/functions';
import { Link, Redirect } from 'react-router-dom';
import SearchBar from '../../components/Ui/SearchBar/SearchBar';

class Home extends Component {
    
    state = {
        bogeEntries: [],
        topCulprits: [],
        displayOptions: [
            {
                option: 0,
                title: "Top 10"
            },
            {   
                option: 1,
                title: "All The Rest"
            }
        ],
        shownbogeEntries: Number(localStorage.getItem("tab")) ? Number(localStorage.getItem("tab")) : 0,
        searchInput: "",
        searchInputError: "",
        redirectSearch: ""
    }

    componentDidMount() {
        Axios.get(CONST.BASE_URL + '/api/all-boge-entries').then(response => {
            this.setState({
                bogeEntries: response.data.bogeEntries,
                topCulprits: response.data.topCulprits
            }, () => {
                FUNCTIONS.handleOldScrollPosition();
              });
        }).catch(function (error) {
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
            
              });
    };

    changeHandler = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
            [name + "Error"]: ""
        });
    }

    headerClickHandler = (option) => {
        this.setState({
            shownbogeEntries: option
        })
    }

    submitSearchHandler = () => {
        if(!FUNCTIONS.checkAllowedTextInput(this.state.searchInput, "search")){
            this.setState({
                redirectSearch: <Redirect to={"/user-boge-entries/" + this.state.searchInput} />
            })
        } else {
            this.setState({
                searchInputError: FUNCTIONS.checkAllowedTextInput(this.state.searchInput, "search")
            })
        }
    }

    keyDownHandler = (event) => {
        if(event.key === "Enter"){
            this.submitSearchHandler()
        }
    }

    render() {
        // split top 10 from the rest
        let shownBogeEntries = {
                                    entries: this.state.bogeEntries.slice(0, 10),
                                    topTen: true
                                };

        // show all boge entries
        if(this.state.shownbogeEntries === 1) {
            shownBogeEntries = {
                                    entries: this.state.bogeEntries.slice(10, this.state.bogeEntries.length),
                                    topTen: false
                                };
        }

        window.addEventListener("keydown", this.keyDownHandler);

        return(
            <div className={classes.Home}>
                {this.state.redirectSearch}
                <div className={classes.headerContainer}>
                    <div className={classes.optionsContainer}>
                        {this.state.displayOptions.map((displayOption, key) =>
                            <h4 className={displayOption.option === this.state.shownbogeEntries 
                                ? classes.selected + " main-button" : classes.unselected} 
                            key={key}
                            onClick={this.headerClickHandler.bind(this, displayOption.option)}
                            >
                                {displayOption.title}
                            </h4>
                        )}
                    </div>
                    <div className={classes.searchTopTwoContainer}>
                        <div className={classes.topTwoContainer}><h3>Top 2 Culprits: </h3>{this.state.topCulprits.map((culprit, key) =>
                            <Link key={key}to={"/user-boge-entries/" + culprit.id + "/culprits"}><h4>{culprit.name} ({culprit.boge_accusations_count})</h4></Link>
                            )}
                        </div>
                        <SearchBar searchInput={this.state.searchInput} changeHandler={this.changeHandler} submitSearchHandler={this.submitSearchHandler} searchInputError={this.state.searchInputError}/>
                    </div>
                </div>
                {shownBogeEntries.entries.map((bogeEntries, key) =>
                    <BogeEntrySnippet
                        error={bogeEntries.grammar_error}
                        intention={bogeEntries.grammar_intention}
                        level={bogeEntries.error_level}
                        maker={bogeEntries.error_maker}
                        votes={bogeEntries.boge_entry_votes.length}
                        id={bogeEntries.id}
                        key={key}
                        badgeNumber={shownBogeEntries.topTen ? key + 1 : ""}
                        tab={this.state.shownbogeEntries}
                    />
                )}
            </div>
        )
    }
}

export default Home