import classes from './AnswerToMark.module.css';
import { FaCheckSquare, FaTimes } from "react-icons/fa";
import axios from 'axios';
import CONST from '../../../../constants/constants';
import { useState } from 'react';

export default function AnswerToMark(props){

    const [marked, setMarked] = useState({
        marked: props.answerToMark.marked
    });

    const incorrectAnswerMarkHandler = function(){
        axios.defaults.withCredentials = true;
        axios.post(CONST.BASE_URL + '/api/post-quiz-incorrect-marked-answer/' + props.answerToMark.id)
        .then(response => {
            props.startingValues();
            setMarked({
                marked: true
            })
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    const correctAnswerMarkHandler = function(points){
        axios.defaults.withCredentials = true;
        axios.post(CONST.BASE_URL + '/api/post-quiz-correct-marked-answer', {answer_id: props.answerToMark.id, correct_points: points})
        .then(response => {
            props.startingValues();
            setMarked({
                marked: true
            })
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    const changeMarkHandler = function(){
        setMarked({
            marked: false
        })
    }

    let markedPoints = 0;

    const maximumAnswerValue = props.answerToMark.quiz_question.bonus_questions + 1;

    let answerControls =    
    <div className={classes.answerControlsContainer}>
        <FaCheckSquare onClick={correctAnswerMarkHandler.bind(this, maximumAnswerValue)} className={classes.twoAnswerControl + " " + classes.correct}/>
        <FaTimes onClick={incorrectAnswerMarkHandler} className={classes.twoAnswerControl + " " + classes.incorrect}/>
    </div>

    if(marked.marked){
        markedPoints = props.answerToMark.correct_points;
        answerControls = 
        <div className={classes.answerControlsContainer}>

            <h2 onClick={changeMarkHandler}>Change Mark?</h2>
        </div>
    } else if(maximumAnswerValue === 2){
        answerControls =    
        <div className={classes.answerControlsContainer}>
            <div onClick={correctAnswerMarkHandler.bind(this, 1)} className={classes.answerNumberContainer}>
                <FaCheckSquare className={classes.answerNumberCheck + " " + classes.correct}/>
                <h2 className={classes.answerNumberValue + " " + classes.correct}>1</h2>
            </div>
            <div onClick={correctAnswerMarkHandler.bind(this, 2)} className={classes.answerNumberContainer}>
                <FaCheckSquare className={classes.answerNumberCheck + " " + classes.correct}/>
                <h2 className={classes.answerNumberValue + " " + classes.correct}>2</h2>
            </div>
            <FaTimes onClick={incorrectAnswerMarkHandler} className={classes.twoAnswerControl + " " + classes.incorrect}/>
        </div>
    }

    return(
        <div className={classes.answerToMark}>
            <h2 className={classes.answerNumber}>{props.answerNumber}</h2>
            <h2 className={classes.answer}>{props.answerToMark.answer}</h2>
            <h2 className={classes.answerMaxValue}>{markedPoints} / {props.answerToMark.quiz_question.bonus_questions + 1}</h2>
            {answerControls}
            <div className={classes.teamAnswerContainer}>
                <h4 className={classes.teamAnswerTitle}>Your Answer:</h4>
                <h4 className={classes.teamAnswerAnswer}>{props.teamAnswer}</h4>
            </div>
        </div>
    )
}