import { useEffect, useState } from 'react';
import classes from './ResultsWaitingRoom.module.css';
import axios from 'axios';
import CONST from '../../../constants/constants';
import { FaTrophy } from "react-icons/fa";

export default function ResultsWaitingRoom(props){

    const [results, setResults] = useState({
        results: "",
        quiz: ""
    });

    const revealResults = function(){
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/get-quiz-results/' + props.match.params.id).then(response => {
            if(response.data.quiz.completed){
                setResults({
                    results: response.data.results,
                    quiz: response.data.quiz.name
                })
            }
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    useEffect(() => {
        let refreshTeamNames = setInterval(revealResults, 3000);
        return () => {
            clearInterval(refreshTeamNames);
        }
    }, []);

    function getNumberWithOrdinal(n) {
        var s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      }

    let finalResults = <h1>Awaiting Results. Good Luck</h1>;

    if(results.results.length > 0){
        let resultsSorted = results.results.sort((a, b) => b.score - a.score);

        if(resultsSorted.length > 0){
            if(resultsSorted[0]){
                finalResults = 
                <div className={classes.resultsContainer}>
                    <h1>{results.quiz}</h1>
                    <h2>Final Standings</h2>
                    {results.results.map((name, i) =>
                    <div className={classes.resultContainer} key={i}>
                            {i+1 === 1 ? <div className={classes.championsContainer}><FaTrophy /> <p>Champion</p> <FaTrophy /></div> : ""}
                            <div className={classes.postionContainer}>
                                <h2>{getNumberWithOrdinal(i+1)}</h2>
                                <p>{name.name}</p>
                                <p>{name.team_members}</p>
                                <p>{name.score}</p>
                            </div>
                    </div>
                    )}
                </div>
            }
        }
    }

    return(
        <div className={classes.resultsWaitingRoom}>
            {finalResults}
        </div>
    )
}