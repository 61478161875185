import React from 'react';
import { Link} from 'react-router-dom';
import styles from './BogeEntrySnippet.module.css';
import BogeEntryErrorLevel from '../BogeEntrySnippet/BogeEntryErrorLevel/BogeEntryErrorLevel';
import Badge from '../Ux/Badge/Badge';
import FUNCTIONS from '../../functions/functions';

const gramerrSnippet = (props) => {
    let error_level = props.level;
    error_level = error_level.replace(/\.0$/,'');

    return(
        <Link to={'/boge-entry-single' + props.id} className={styles.BogeEntrySnippet} onClick={FUNCTIONS.handleStoringScrollPosition.bind(this, props.tab)}>
            <h5 className={styles.maker}>{props.maker}:</h5>
            <h3 className={styles.error}>"{props.error}"</h3>
            <h5 className={styles.intentionHeader}>Intention:</h5>
            <h3 className={styles.intention}>{props.intention}</h3>
            {props.badgeNumber ? <Badge badgeNumber={props.badgeNumber} /> : ""}
            <BogeEntryErrorLevel error_level={error_level} votes={props.votes} />
        </Link>
    )
};

export default gramerrSnippet
