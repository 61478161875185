import Axios from 'axios';
import React, { Component } from 'react';
import styles from './MyProfile.module.css';
import CONST from '../../constants/constants';
import { Link, Redirect } from 'react-router-dom';
import LoadingScreen from '../../components/Ux/LoadingScreen/LoadingScreen';
import Switch from "react-switch";
import GoBack from '../../components/Ui/GoBack/GoBack';
import { GrAddCircle } from "react-icons/gr";
import giftImage from './giftrangers.png';
import ce from './CE.jpg';
import jw from './JW.jpg';
import giftRevealImageOne from './clue-one.png'
import giftRevealImageTwo from './clue-two.png'

const user = JSON.parse(localStorage.getItem("user"));
const playerOne = "N/A";
const playerTwo = "N/A";

class MyProfile extends Component {

    state = {
        name: "",
        email: "",
        avatar: "",
        avatarStatus: false,
        votes: "",
        entries: "",
        comments: "",
        newImage: "",
        newImageErrorMessage: "",
        redirect: "",
        loadingScreen: "",
        audio: "",
        checked: false,
        puzzle: false,
        scrambleWord: false,
        scrambledWordInput: "",
        finalClue: false,
        finalClueError: "",
        giftShake: true,
        giftReveal: false,
        newGroupName: "",
        groups: []
    }

    componentDidMount(){
        let audio = new Audio(CONST.BASE_URL + "/storage/audio/profile-audio/hellosusie.mp3")
        let audio1 = new Audio(CONST.BASE_URL + "/storage/audio/profile-audio/scoobie.mp3")
        let audio2 = new Audio(CONST.BASE_URL + "/storage/audio/profile-audio/lee.mp3")

        let avatar = <h3 className={styles.avatar} onClick={() => this.fileInput.click()}>Avatar Not Available. Tap here to add one</h3>;
        let avatarStatus = false;

        let checked = false;

        let emailPlay = "";

        let puzzle = false;

        // THIS IS FOR PUTTING A PUZZLE CLUE
        if(user.email === playerOne || user.email === playerTwo) {
            puzzle = true;
        }

        if(user.email === "sdonnan69@yahoo.co.uk") {
            emailPlay = audio.play();
        }

        if(user.email === "bennewithc@yahoo.com") {
            emailPlay = audio1.play();
        }

        if(user.email === "warrencross47@hotmail.com") {
            emailPlay = audio2.play();
        }

        Axios.defaults.withCredentials = true;
        Axios.get(CONST.BASE_URL+'/api/my-profile/' + user.id).then(response => {
            if(response.data){
                if(response.data.avatar){
                    avatar = <img src={CONST.BASE_URL+"/storage/images/avatar-images/"+response.data.avatar} onClick={() => this.fileInput.click()} className={styles.avatar} alt="" />;
                    avatarStatus = true;
                }
                if(response.data.new_boge_notifications) {
                    checked = true;
                }
                this.setState({
                    name: user.name,
                    email: user.email,
                    avatar: avatar,
                    avatarStatus: avatarStatus,
                    votes: response.data.votes,
                    entries: response.data.entries,
                    comments: response.data.comments,
                    audio: emailPlay,
                    checked: checked,
                    puzzle: puzzle
                })
            } else {
                this.setState({redirect: <Redirect to="/" />});
            }
        })
    }

    fileSelectedHandler = (event) => {
        this.setState({
            loadingScreen: <LoadingScreen message="Optimizing image. Just a moment"/>,
            newImage: event.target.files[0]
            }, () => {
                if(this.state.newImage){
                    let fd = new FormData();
                    fd.append('newImage', this.state.newImage, this.state.newImage.name);
                    Axios.post(CONST.BASE_URL+'/api/change-avatar', fd).then(response => {
                        this.setState({
                            loadingScreen: "",
                            avatar: <img src={CONST.BASE_URL+"/storage/images/avatar-images/" + response.data.avatar} onClick={() => this.fileInput.click()} className={styles.avatar} alt="" />,
                            newImageErrorMessage: "",
                            avatarStatus: true
                        })
                    })
                }
            })
    }

    changeNewBogeEntryNotificationHandler(checked) {
        Axios.post(CONST.BASE_URL + '/api/change-new-boge-notification-preference/' + user.id, {checked: checked});
        this.setState({ 
            checked 
        });
    }

    scrambleWordHandler = (event) => {
        event.preventDefault();
        if(user.email === playerOne || user.email === playerTwo) {
            this.setState({
                scrambleWord: !this.state.scrambleWord
            })
        }
    }

    scrambledWordInputHander = (event) => {
        this.setState({
            scrambledWordInput: event.target.value.toUpperCase()
        })
    }

    checkWordInputHandler = (event) => {

        if(user.email === playerOne && this.state.scrambledWordInput === "I LOVE ACCOUNTING") {
            this.setState({
                finalClue: true,
                giftShake: false,
            })
        } else if(user.email === playerTwo && this.state.scrambledWordInput === "WATER CHESTNUT") {
            this.setState({
                finalClue: true,
                giftShake: false,
            })
        } else {
            this.setState({
                finalClueError: <p className="error">This is incorrect! Try Again</p>
            })
        }
    }

    revealGiftHandler = (event) => {
        this.setState({
            giftShake: true,
            giftReveal: true
        })
    }

    groupdNameChangeHandler = (event) => {
        this.setState({
            newGroupName: event.target.value
        });
    }

    createNewGroup = (event) => {
        event.preventDefault();
        Axios.defaults.withCredentials = true;
        Axios.post(CONST.BASE_URL + '/api/create-new-group', {
            id: user.id,
            newGroupName: this.state.newGroupName
        }).then(response => {
            console.log(response);
        })
    }

    render(){
        let henrysRental = "";
        let uploadAvatarInfo = "";
        let puzzle = "";
        let giftAnimation = styles.giftAnimation;
        let giftReveal = ""                       ;

        if(this.state.puzzle){
            puzzle =    <div className={styles.puzzle + " main-button"} onClick={this.scrambleWordHandler}>
                            <h3>{this.state.name.split(" ")[0]}</h3>
                            <h4>You have an anagram to solve!</h4>
                            <h4>Tap here to start</h4>
                        </div>
        }

        if(this.state.giftShake) {
            giftAnimation = styles.noGiftAnimation;
        }

        if(this.state.giftReveal && user.email === playerOne) {
            giftReveal =    <div className={styles.giftImageRevealed + " " + styles.Open}>
                                <img src={giftRevealImageOne} alt="" />
                            </div>;   
        } else if(this.state.giftReveal && user.email === playerTwo){
            giftReveal =    <div className={styles.giftImageRevealed + " " + styles.Open}>
                                <img src={giftRevealImageTwo} alt="" />
                            </div>;  
        }
        
        if(this.state.avatarStatus === true){
            uploadAvatarInfo = 
                <div className={styles.uploadAvatarInfo}>
                    <h5 onClick={() => this.fileInput.click()}>This is your current Avatar. Tap the image to change it</h5>
                </div>;
        }
        
        if(this.state.newImageErrorMessage) {
            uploadAvatarInfo = 
                <div className={styles.uploadAvatarInfo}>
                    {this.state.newImageErrorMessage}
                </div>;
        }

        let scrambledWord = "";

        if(this.state.scrambleWord && user.email === playerOne) {
            scrambledWord = <div className={styles.scrambledWordContainer}>
                                <p className={styles.close} onClick={this.scrambleWordHandler}>X Close</p>
                                <h2>Figure out the scrambled words. Clue: It's something to do with your job</h2>
                                <h1>I eovl nigotucnca</h1>
                                <input onChange={this.scrambledWordInputHander} value={this.state.scrambledWordInput} type="text" />
                                <button onClick={this.checkWordInputHandler} className="main-button">submit</button>
                                {this.state.finalClueError}
                            </div>
        } else if(this.state.scrambleWord && user.email === playerTwo) {
            scrambledWord = <div className={styles.scrambledWordContainer}>
                                <p className={styles.close} onClick={this.scrambleWordHandler}>X Close</p>
                                <h2>Figure out the scrambled words. Clue: it's one of your least favourite foods</h2>
                                <h1>arewt tetsnhcu</h1>
                                <input onChange={this.scrambledWordInputHander} value={this.state.scrambledWordInput} type="text" />
                                <button onClick={this.checkWordInputHandler} className="main-button">submit</button>
                                {this.state.finalClueError}
                            </div>
        } 

        if(this.state.finalClue) {
            scrambledWord = <div className={styles.scrambledWordContainer}>
                                <h2>Yaaaaaaaay You did it.</h2>
                                <h2>Click the Present Box below to reveal your Gift clue</h2>
                                <br/>
                                <h1><img className={giftAnimation} src={giftImage} alt="" onClick={this.revealGiftHandler} /></h1>
                            </div>
        }

        if(this.state.giftReveal){

        }

        if(user.family_member){
            henrysRental = <Link className={styles.henrysRental} to="/henrys-rental/">
                <img className={styles.jw} src={jw} alt="" />
                <h3>Henry's Rental</h3>
                <img className={styles.ce} src={ce} alt="" />
            </Link>
        }

        return(
            <div className={styles.MyProfile}>
                <GoBack back={() => this.props.history.goBack()} />
                {this.state.loadingScreen}
                {this.state.redirect}
                {scrambledWord}
                {giftReveal}
                <div className={styles.userInfo}>  
                    <div className={styles.uploadAvatarInfoContainer}>
                        {this.state.avatar}
                        <input
                        style={{display: 'none'}}
                        type="file"
                        name="newImage"
                        onChange={this.fileSelectedHandler}
                        ref={fileInput => this.fileInput = fileInput}
                        />
                        {uploadAvatarInfo}
                    </div>
                    <Link className={styles.entries + " main-button"} to={"/user-boge-entries/" + JSON.parse(localStorage.getItem("user")).id + "/entries"}>
                        <p>Entries {this.state.entries}</p>
                    </Link>
                    <div>
                        <p>Votes</p>
                        <h5>{this.state.votes}</h5>
                    </div>
                    <div>
                        <p>Comments</p>
                        <h5>{this.state.comments}</h5>
                    </div>
                </div>
                {henrysRental}
                {puzzle}
                <div className={styles.userPreferencesContainer}>
                    <h5>Preferences</h5>
                    <Switch onChange={this.changeNewBogeEntryNotificationHandler.bind(this)} checked={this.state.checked} />
                    <p>Receive Notifications for new Boge Entries</p>
                </div>
                <div className={styles.groupsContainer}>
                    <h5>Groups</h5>
                    <div className={styles.groupsOption}>
                        <h5>Pending Invitations</h5>
                        <p>Invitation to join Some Group</p>
                    </div>
                    <div className={styles.groupsOption}>
                        <h5>Current Group(s):</h5>
                        <p>Cheese, Lemons, Balls</p>
                        {this.state.groups}
                    </div>
                    <div className={styles.groupsOption + " " + styles.newGroup}>
                        <h5>Create New Group</h5>
                        <div>
                            <input 
                                type="text"
                                name="maker"
                                autoComplete="off"
                                value={this.state.newGroupName}
                                onChange={this.groupdNameChangeHandler}
                            />
                            <GrAddCircle onClick={this.createNewGroup} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MyProfile