import { useEffect, useState } from 'react';
import classes from './QuizLandingPage.module.css';
import LoadingScreen from '../../../components/Ux/LoadingScreen/LoadingScreen';
import axios from 'axios';
import CONST from '../../../constants/constants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { Redirect } from 'react-router-dom';
import ConfirmDelete from '../../../components/Ui/ConfirmDelete/ConfirmDelete';

const user = JSON.parse(localStorage.getItem("user"));

export default function QuizLandingPage(){

    const [quizzes, setQuizzes] = useState({
        activeQuizzes: [],
        completedQuizzes: [],
        userCompletedQuizzes: []
    });

    const [team, setTeam] = useState({
        name: "",
        teamMambers: ""
    });

    const [loadingScreen, setLoadingScreen] = useState({
        loadingScreen: <LoadingScreen />
    })

    const [newQuizName, setNewQuizName] = useState({
        name: ""
    });

    const [newQuizQuestions, setNewQuizQuestions] = useState({
        amount: ""
    });

    const [newQuizErrors, setNewQuizErrors] = useState({
        nameError: "",
        amountError: ""
    });

    const [quizCode, setQuizCode] = useState({
        code: ""
    });

    const [quizCodeErrors, setQuizCodeErrors] = useState({
        codeError: "",
    });

    const [redirect, setRedirect] = useState({
        url: ""
    });

    const [confirmDelete, setConfirmDelete] = useState({
        id: "",
        shown: false
    });

    useEffect(() => {
        startingValues();
    }, []);

    const startingValues = function(){
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/get-all-quizzes').then(response => {
            if(!user.admin && !response.data.team){
                setRedirect({
                    url:    <Redirect to={{
                                pathname: "/set-team-name"
                            }}                  
                        />
                })
            } else {
                if(!user.admin){
                    setTeam({
                        name: response.data.team.name,
                        teamMambers: response.data.team.team_members
                    })
                }
                setQuizzes({
                    activeQuizzes: response.data.activeQuizzes,
                    completedQuizzes: response.data.completedQuizzes,
                    userCompletedQuizzes: response.data.userCompletedQuizzes != null ? response.data.userCompletedQuizzes.quizzes : ""
                })
                setLoadingScreen({
                    loadingScreen: ""
                })              
            }
        }).catch(function (error) {
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
            
              });
    }

    const quizNameChangeHandler = function(event){
        setNewQuizName({
            name: event.target.value
        })
        setNewQuizErrors({
            nameError: "",
            amountError: ""
        })
    }

    const quizQuestionsChangeHandler = function(event){
        setNewQuizQuestions({
            amount: event.target.value
        })
        setNewQuizErrors({
            nameError: "",
            amountError: ""
        })
    }

    const quizCodeChangeHandler = function(event){
        setQuizCode({
            code: event.target.value
        })
        setQuizCodeErrors({
            codeError: ""
        })
    }

    const handleSubmitQuizName = function(event){
        event.preventDefault();

        let newQuizNameErrorMessage = "";
        let newQuizNumberOfQuestionsErrorMessage = "";

        if(newQuizName.name === ""){
            newQuizNameErrorMessage = <h4 className="error">Team Name cannot be empty</h4>;
        }

        if (/[^a-zA-Z0-9 -,?!'`]/.test(newQuizName.name)) {
            newQuizNameErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(Number(newQuizQuestions.amount) < 10){
            newQuizNumberOfQuestionsErrorMessage = <h4 className="error">The Quiz must have a minimum of 10 Questions</h4>;
        } else if(newQuizQuestions.amount === ""){
            newQuizNumberOfQuestionsErrorMessage = <h4 className="error">Number Of Questions cannot be empty</h4>;
        }

        if (/[^0-9]/.test(newQuizQuestions.amount)) {
            newQuizNumberOfQuestionsErrorMessage = <h4 className="error">Please enter only numbers</h4>;
        }

        if(!newQuizNameErrorMessage && !newQuizNumberOfQuestionsErrorMessage){
            axios.defaults.withCredentials = true;
            axios.post(CONST.BASE_URL + '/api/set-quiz-name', {newQuizName: newQuizName.name, newQuizNumberOfQuestions: newQuizQuestions.amount}).then(response => {
                setLoadingScreen({
                    loadingScreen: <LoadingScreen />
                })
                setNewQuizName({
                    name: ""
                })
                setNewQuizQuestions({
                    amount: ""
                })
                startingValues();
            })
            .catch(function (error) {
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
            
              });
        } else {
            setNewQuizErrors({
                nameError: newQuizNameErrorMessage,
                amountError: newQuizNumberOfQuestionsErrorMessage
            })
        }
      }

    const handleSubmitQuizCode = function(){

        let quizCodeErrorMessage = "";

        if(quizCode.code === ""){
            quizCodeErrorMessage = <h4 className="error">Team Name cannot be empty</h4>;
        }

        if (/[^0-9]/.test(quizCode.code)) {
            quizCodeErrorMessage = <h4 className="error">Please enter only numbers</h4>;
        }

        if(!quizCodeErrorMessage){
            axios.defaults.withCredentials = true;
            axios.post(CONST.BASE_URL + '/api/submit-quiz-code', {quizCode: quizCode.code}).then(response => {
                setLoadingScreen({
                    loadingScreen: <LoadingScreen />
                })
                if(response.data !== false){
                    
                    setQuizCode({
                        code: ""
                    })
                    setLoadingScreen({
                        loadingScreen: ""
                    })
                    setRedirect({
                        url:    <Redirect to={{
                                    pathname: "/currently-playing-quiz/" + response.data
                                }}                  
                            />
                    })
                } else {
                    setLoadingScreen({
                        loadingScreen: ""
                    })
                    setQuizCodeErrors({
                        codeError: <h4 className="error">This quiz does not exist. Please try again</h4>
                    })
                }
            })
            .catch(function (error) {
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
            
              });
        } else {
            setQuizCodeErrors({
                codeError: quizCodeErrorMessage
            })
        }
    }

    function deleteQuizHandler(){
        axios.defaults.withCredentials = true;
        axios.delete(CONST.BASE_URL + '/api/delete-quiz/' + confirmDelete.id).then(response => {
            setConfirmDelete({
                id: "",
                shown: false
            })  
            setLoadingScreen({
                loadingScreen: <LoadingScreen />
            })
            startingValues();
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    const confirmDeleteHandler = function(id){
        setConfirmDelete({
            id: id,
            shown: !confirmDelete.shown
        })
    }

    let quizCodeContainer = 
    <div className={classes.quizCodeContainer}>
        <h2>Team Name</h2>
        <h3>{team.name}</h3>
        <h2>Team Members</h2>
        <h3>{team.teamMambers}</h3>
        <h1>Join New Quiz</h1>
        <input 
            className={classes.teamName}
            type="number"
            name="quizCode"
            placeholder="Enter Quiz Code"
            autoComplete="off"
            value={quizCode.code}
            onChange={quizCodeChangeHandler}
        />
        <button className={"main-button " + classes.lockInButton} onClick={handleSubmitQuizCode}>Submit Quiz Code</button>
        {quizCodeErrors.codeError}
    </div>;

    let newQuiz = "";
    let activeQuizzes = "";
    let completedQuizzes = "";
    let userCompletedQuizzes = "";

    if(user.admin){
        quizCodeContainer = "";
        newQuiz = <div className={classes.newQuizNameContainer}>
                    <h1>New Quiz</h1>
                    <input 
                        className={classes.teamName}
                        type="text"
                        name="newQuizName"
                        placeholder="Enter Quiz Name"
                        autoComplete="off"
                        value={newQuizName.name}
                        onChange={quizNameChangeHandler}
                    />
                    {newQuizErrors.nameError}
                    <input 
                        className={classes.teamName}
                        type="number"
                        name="newQuizNumberOfQuestions"
                        placeholder="Enter Number Of Questions"
                        autoComplete="off"
                        value={newQuizQuestions.amount}
                        onChange={quizQuestionsChangeHandler}
                    />
                    {newQuizErrors.amountError}       
                    <button className={"main-button "+classes.lockInButton} onClick={handleSubmitQuizName}>Submit Quiz Name</button>
                </div>
        activeQuizzes = <div className={classes.quizzesContainer}>
                            <h1>Active Quizzes</h1>
                            {quizzes.activeQuizzes.map((quiz, i) => 
                                <div className={classes.activeQuiz} key={i}><h2>{i + 1} - {quiz.name}</h2><Link to={"edit-active-quiz/" + quiz.id} className={classes.quizName}><FaRegEdit className='edit'/></Link><MdDeleteForever onClick={confirmDeleteHandler.bind(this, quiz.id)} className='delete' /></div>
                            )}
                        </div>
        completedQuizzes = <div className={classes.quizzesContainer}>
                                <h1>Completed Quizzes</h1>
                                {quizzes.completedQuizzes.map((quiz, i) => 
                                    <Link key={i} to={"completed-quiz/" + quiz.id} className={classes.quizName}><h2>{quiz.name}</h2></Link>
                                )}
                            </div>
    } else {
        userCompletedQuizzes = <div className={classes.quizzesContainer}>
                                <h1>Completed Quizzes ({quizzes.userCompletedQuizzes.length})</h1>
                                {quizzes.userCompletedQuizzes.map((quiz, i) => 
                                    <Link key={i} to={"completed-quiz/" + quiz.id} className={classes.quizName}><h2>{quiz.name}</h2></Link>
                                )}
                            </div>
    }

    let confirmDeleteModal = "";

    if(confirmDelete.shown){
        confirmDeleteModal = <ConfirmDelete delete={deleteQuizHandler} clicked={confirmDeleteHandler}/>;
    }

    return(
        <div className={classes.QuizLandingPage}>
            {redirect.url}
            {loadingScreen.loadingScreen}
            {quizCodeContainer}
            {newQuiz}
            {activeQuizzes}
            {completedQuizzes}
            {userCompletedQuizzes}
            {confirmDeleteModal}
        </div>
    )
}