import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import styles from './Login.module.css';
import CONST from '../../../constants/constants';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import LoadingScreen from '../../Ux/LoadingScreen/LoadingScreen';
import FUNCTIONS from '../../../functions/functions';

class Login extends Component {

    state = {
        user: "",
        email: "",
        password: "",
        errorMessage: "",
        authStatus: "Not Logged In",
        redirectOnSuccess: "",
        passwordEye: true,
        loadingScreen: false
    }

    emailChangeHandler = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    passwordChangeHandler = (event) => {
        this.setState({
            password: event.target.value,
            errorMessage: ""
        });
    }

    loginHandler = (event) => {
        event.preventDefault();
        this.setState({
            loadingScreen: <LoadingScreen message="Logging You In Now" />
        }) 
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/sanctum/csrf-cookie').then(response => {
            axios.post(CONST.BASE_URL + '/api/login', {
                email: this.state.email,
                password: this.state.password
            }).then(response => {
    
                if(response.data.message === "user not registered"){
                    localStorage.setItem('user', JSON.stringify(response.data.authUser));
                    this.setState({
                        redirectOnSuccess: <Redirect to="/email-verification" />
                    })
                }
                
                if(response.data.message === "Login successful"){
                    localStorage.setItem('user', JSON.stringify(response.data.authUser));
                    this.setState({
                        redirectOnSuccess: <Redirect to={"/"} />
                    })
                    window.location.reload(false);
                }
            }
            , (error) => {
                this.setState({
                    errorMessage: <h4 className="error">That username and password combination is incorrect. Please try again.</h4>,
                    loadingScreen: false
                })
            })
        })
    }

    passwordEyeToggle = () => {
        this.setState({
            passwordEye: !this.state.passwordEye
        })
      }

    render(){

        let successMsg = "";
        let passwordEye = <AiFillEyeInvisible onClick={this.passwordEyeToggle} />;
        let passwordStatus = document.getElementById("password");

        if(passwordStatus){
            if (!this.state.passwordEye) {
                passwordEye = <AiFillEye onClick={this.passwordEyeToggle} />;
                passwordStatus.type = "text";
            } else {
                passwordStatus.type = "password";
            }
        }

        if (this.props.location.state !== undefined) {
            const message = this.props.location.state.fromRedirect;
            if (message) {
                successMsg = FUNCTIONS.flashMessage("success", message)
                setTimeout(() => {
                    successMsg = FUNCTIONS.flashMessage("empty", "")
                }, 1000);
            }
            }    

        return(
            <form className={styles.Login}>
                {this.state.loadingScreen}
                {successMsg}
                {this.state.redirectOnSuccess}
                <input type="email" name="email" placeholder="Email" onChange={this.emailChangeHandler}/>
                <div className={styles.passwordInputContainer}><input type="password" name="password" placeholder="Password" id="password" onChange={this.passwordChangeHandler} />{passwordEye}</div>
                {this.state.errorMessage}
                <button className="main-button" onClick={this.loginHandler}>Login</button>
                <p>Forgot Your Password <Link to="/forgot-password-email">Reset here</Link></p>
                <p>Don't have an account? <Link to="/sign-up">Sign Up Here</Link></p>
            </form>
        )
    }
}

export default Login