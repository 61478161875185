import classes from './HenryRentalTitleModal.module.css';
import { ImCross } from "react-icons/im";
import CONST from '../../../constants/constants';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import LoadingScreen from '../../../components/Ux/LoadingScreen/LoadingScreen';

export default function HenryRentalTitleModal(props){

    const imagesArray = props.henrysImages;

    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const [henryRentalSingleMedia, setHenryRentalSingleMedia] = useState({
        title: "",
        categories: [],
        whoHasIt: "",
        whoHasItId: "",
        frontImage: "",
        backImage: "",
        transferred: "",
        addedBy: "",
        mediaType: "",
        randomImage: "",
        loadingScreen: <LoadingScreen henrysImageModal={true} />
    });

    const [image, setImage] = useState({
        frontImage: true
    });

    useEffect(() => {
        Axios.defaults.withCredentials = true;
        Axios.get(CONST.BASE_URL + '/api/get-single-henrys-rental-media/' + props.id).then(response =>{
            setHenryRentalSingleMedia({
                title: response.data.title,
                categories: response.data.henrys_rental_media_categories,
                whoHasIt: response.data.who_has_it_user.name,
                whoHasItId: response.data.who_has_it_user.id,
                frontImage: response.data.image_front,
                backImage: response.data.image_back,
                transferred: response.data.times_transferred,
                addedBy: response.data.henrys_rental_media_user.name,
                mediaType: response.data.media_type,
                randomImage: imagesArray[randomIntFromInterval(0, props.henrysImages.length - 1)].name,
                loadingScreen: ""
            })
        })
        // Reset all fields when closed
        return () => {
            setHenryRentalSingleMedia({
                title: "",
                categories: [],
                whoHasIt: "",
                whoHasItId: "",
                frontImage: "",
                backImage: "",
                transferred: "",
                addedBy: "",
                mediaType: "",
                loadingScreen: ""
            })
        }
    }, []);

    const handleImageViewHandler = function(){
        setImage({
            frontImage: !image.frontImage
        })
    }

    let shownImage = <img onClick={handleImageViewHandler} src={CONST.BASE_URL+"/storage/images/henrys-rental-images/" + henryRentalSingleMedia.frontImage + ".webp"} className={classes.imageFront} alt="" />

    if(!image.frontImage) {
        shownImage = <img onClick={handleImageViewHandler} src={CONST.BASE_URL+"/storage/images/henrys-rental-images/" + henryRentalSingleMedia.backImage + ".webp"} className={classes.imageBack} alt="" />
    }

    let whoHasIt = "";
    
    // Check who has it
    if(JSON.parse(localStorage.getItem("user")).id === henryRentalSingleMedia.whoHasItId){
        whoHasIt = "You";
    } else {
        whoHasIt = henryRentalSingleMedia.whoHasIt   
    }

    return(
        <div className={classes.henryRentalTitleModal}>
            {henryRentalSingleMedia.loadingScreen}
            <div className={classes.close} onClick={props.closeTitleModalHandler}>
                <ImCross />
                <h2>Close</h2>
            </div>
            <h1 className={classes.title}>{henryRentalSingleMedia.title}</h1>
            <div className={classes.detailsContainer}>
                <div className={classes.details}>
                    <h2>{henryRentalSingleMedia.mediaType}</h2>
                    <div className={classes.categoriesContainer}>
                        {henryRentalSingleMedia.categories.map((henrys_rental_media_category, i) =>
                            <h3 key={henrys_rental_media_category.id}>{henrys_rental_media_category.category_name}</h3>
                        )}
                    </div>
                </div>
                <img className={classes.detailsImage} src={CONST.BASE_URL+"/storage/images/henrys-images/" + henryRentalSingleMedia.randomImage + ".webp"} alt="henrys media choose logo"/>
            </div>
            <div className={classes.imagesContainer}>
                {shownImage}
            </div>
            <div className={classes.bottomDetailsContainer}>
                <div className={classes.bottomDetailsContainerMini}>
                    <p>Times Transferred</p>
                    <p> {henryRentalSingleMedia.transferred}</p>
                </div>
                <div className={classes.bottomDetailsContainerMini}>
                    <p>Who has it</p>
                    <p>{whoHasIt}</p>
                </div>
                <div className={classes.bottomDetailsContainerMini}>
                    <p>Added By</p>
                    <p>{henryRentalSingleMedia.addedBy}</p>
                </div>
            </div>
        </div>
    )
}