import axios from 'axios';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './Register.module.css';
import EmailValidator from 'email-validator';
import CONST from '../../../constants/constants';
import LoadingScreen from '../../Ux/LoadingScreen/LoadingScreen';

class Register extends Component {

    state = {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        nameErrorMessage: "",
        passwordErrorMessage: "",
        emailErrorMessage: "",
        errorMessage: "",
        redirectOnSuccess: "",
        loadingScreen: false
    }

    nameChangeHandler = (event) => {
        event.preventDefault();
        if(this.state.nameErrorMessage){
            this.setState({
                nameErrorMessage: ""
            })
        }
        this.setState({
            name: event.target.value
        }, () => {
        })
    }

    emailChangeHandler = (event) => {
        event.preventDefault();
        if(this.state.emailErrorMessage){
            this.setState({
                emailErrorMessage: ""
            })
        }
        this.setState({
            email: event.target.value
        }, () => {
        })
    }

    passwordChangeHandler = (event) => {
        event.preventDefault();
        if(this.state.passwordErrorMessage){
            this.setState({
                passwordErrorMessage: ""
            })
        }
        this.setState({
            password: event.target.value
        }, () => {
        })
    }

    confirmPasswordChangeHandler = (event) => {
        event.preventDefault();
        if(this.state.passwordErrorMessage){
            this.setState({
                passwordErrorMessage: ""
            })
        }
        this.setState({
            confirmPassword: event.target.value
        }, () => {
        })
    }

    submitHandler = (event) => {
        event.preventDefault();
        
        let passwordErrorMessage = "";
        let emailErrorMessage = "";
        let nameErrorMessage = "";

        if(this.state.password === "" || this.state.confirmPassword === ""){
            passwordErrorMessage = <h4 className="error">Please enter your password</h4>
        }

        if(this.state.password !== this.state.confirmPassword){
            passwordErrorMessage = <h4 className="error">Passwords do not match. Please try again</h4>
        }
        if(!EmailValidator.validate(this.state.email)){
            emailErrorMessage = <h4 className="error">Please use a valid email</h4>
        }

        if (this.state.name.trim === '') {
            nameErrorMessage = <h4 className="error">Please enter your name</h4>
        }

        if (this.state.name.trim().length < 3) {
            nameErrorMessage = <h4 className="error">Please enter a longer name</h4>
        }

        if (/[^a-zA-Z -]/.test(this.state.name)) {
            nameErrorMessage = <h4 className="error">Please enter a valid name</h4>
        }

        if(!passwordErrorMessage && !emailErrorMessage & !nameErrorMessage){
                this.setState({
                    loadingScreen: <LoadingScreen message="Creating your account Now" />
                }) 
                axios.defaults.withCredentials = true;
                axios.get(CONST.BASE_URL + '/sanctum/csrf-cookie').then(response => {
                    axios.post(CONST.BASE_URL + '/api/register', {
                        name: this.state.name,
                        email: this.state.email,
                        password: this.state.password
                    }).then(response => {
                        if(response.data.message === "registration successful"){
                            localStorage.setItem('user', JSON.stringify(response.data.authUser));
                            this.setState({
                                loadingScreen: false,
                                name: "",
                                email: "",
                                password: "",
                                confirmPassword: "",
                                nameErrorMessage: "",
                                passwordErrorMessage: "",
                                emailErrorMessage: "",
                                errorMessage: "",
                                redirectOnSuccess: <Redirect to="/email-verification" />
                            })
                        }
                    }
                    , (error) => {
                        this.setState({
                            loadingScreen: false,
                            errorMessage: <h4 className="error">An account with that email already exists. Please try another email address</h4>
                        })
                    }
                    )
                })
        } else {
            this.setState({
                nameErrorMessage: nameErrorMessage,
                passwordErrorMessage: passwordErrorMessage,
                emailErrorMessage: emailErrorMessage,
            })
        }
    }

    render(){
        return(
            <form className={styles.Register}>
                {this.state.loadingScreen}
                {this.state.redirectOnSuccess}
                <input type="text" name="name" placeholder="Name" onChange={this.nameChangeHandler}/>
                {this.state.nameErrorMessage}
                <input type="email" name="email" placeholder="Email" onChange={this.emailChangeHandler}/>
                {this.state.emailErrorMessage}
                <input type="password" name="password" placeholder="Password" onChange={this.passwordChangeHandler}/>
                <input type="password" name="confirm_password" placeholder="Confirm Password" onChange={this.confirmPasswordChangeHandler}/>
                {this.state.passwordErrorMessage}
                {this.state.errorMessage}
                <button className="main-button" onClick={this.submitHandler}>Register</button>
            </form>
        )
    }
}

export default Register