import Axios from 'axios';
import React, { Component } from 'react';
import classes from './EditBogeEntry.module.css';
import BogeEntryForm from '../../components/BogeEntryForm/BogeEntryForm';
import { Redirect } from 'react-router-dom';
import CONST from '../../constants/constants';

class EditBogeEntry extends Component {

    state = {
        error: "",
        intention: "",
        maker: "",
        context: "",
        errorErrorMessage: "",
        intentionErrorMessage: "",
        makerErrorMessage: "",
        contextErrorMessage: "",
        id: "",
        redirect: false
    }

    componentDidMount() {
        Axios.get(CONST.BASE_URL + '/api/edit-boge-entry/' + this.props.match.params.id).then(response => {
            this.setState({
                error: response.data.grammar_error,
                intention: response.data.grammar_intention,
                context: response.data.context,
                maker: response.data.error_maker,
                id: this.props.match.params.id
            });
        }).then(errors => {

        })
    }

    changeHandler = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
            errorErrorMessage: "",
            intentionErrorMessage: "",
            makerErrorMessage: "",
            contextErrorMessage: ""
        });
    }

    submitHandler = (event) => {
        event.preventDefault();

        let errorErrorMessage = "";
        let intentionErrorMessage = "";
        let makerErrorMessage = "";
        let contextErrorMessage = "";
        
        if(this.state.error.trim === ""){
            errorErrorMessage = <h4 className="error">Boge entry cannot be empty</h4>;
        }

        if(this.state.error.length < 5){
            errorErrorMessage = <h4 className="error">Boge entry must longer 5 characters</h4>;
        }

        if (/[^a-zA-Z0-9 -,?]/.test(this.state.error)) {
            errorErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(this.state.intention.trim === ""){
            intentionErrorMessage = <h4 className="error">Intention cannot be empty</h4>;
        }

        if (/[^a-zA-Z0-9 -,?]/.test(this.state.intention)) {
            intentionErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(this.state.maker.trim === ""){
            makerErrorMessage = <h4 className="error">Culprit cannot be empty</h4>;
        }

        if (/[^a-zA-Z0-9 -]/.test(this.state.maker)) {
            makerErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if (/[^a-zA-Z0-9 -]/.test(this.state.context)) {
            contextErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(!errorErrorMessage && !intentionErrorMessage && !makerErrorMessage && !contextErrorMessage){
            Axios.defaults.withCredentials = true;
            Axios.patch(CONST.BASE_URL + '/api/update-boge-entry', this.state).then(response =>{
                this.setState({redirect: <Redirect to={"/boge-entry-single" + this.props.match.params.id}/>})
            });
        } else {
            this.setState({
                errorErrorMessage: errorErrorMessage,
                intentionErrorMessage: intentionErrorMessage,
                makerErrorMessage: makerErrorMessage,
                contextErrorMessage: contextErrorMessage
            })
        }
    }

    render() {

        let context = this.state.context;
        if(!context){
            context = "";
        }
        return(
            <div className={classes.EditBogeEntry}>
                {this.state.redirect}
                <BogeEntryForm
                    error={this.state.error}
                    intention={this.state.intention}
                    context={context}
                    maker={this.state.maker}
                    errorErrorMessage={this.state.errorErrorMessage}
                    intentionErrorMessage={this.state.intentionErrorMessage}
                    makerErrorMessage={this.state.makerErrorMessage}
                    contextErrorMessage={this.state.contextErrorMessage}
                    changeHandler={this.changeHandler}
                    submit={this.submitHandler}
                />
            </div>
        )
    }
}

export default EditBogeEntry
