import React from 'react';
import styles from './BogeEntryVotingControl.module.css';

const bogeEntryVotingControl = (props) => {

    let votingInstructions = <h4>Use this slider to vote</h4>;

    if(props.votingButtonVisable){
        votingInstructions = <button className={"main-button " + styles.votingButton} onClick={props.submitVoteHandler}>Submit Vote</button>;
    }
        
    let error_voting_level = <h2 className={styles.levelVote}>{props.error_level_vote}</h2>

    if(props.error_level_vote > 9) {
        error_voting_level = <h2 className={styles.equalTo10}>{props.error_level_vote}</h2>
    }

    return(
        <div className={styles.levelVoteContainer}>
            <input 
                className={styles.Slider} 
                type="range" 
                value={props.error_level_vote} 
                name="error_level_vote" 
                min="1" 
                max="10" 
                onChange={props.slideVote}
            />
            {error_voting_level}
            {votingInstructions}
        </div>
    )
}

export default bogeEntryVotingControl