import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classes from './KnownCulprits.module.css';

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  state = {
    // The active selection's index
    activeSuggestion: 0,
    // The suggestions that match the user's input
    filteredSuggestions: [],
    // Whether or not the suggestion list is shown
    showSuggestions: false,
    // What the user has entered
    userInput: ""
  };

  // Event fired when the input value is changed
  onChange = e => {
    
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;
    this.props.sendData(userInput);

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.error_maker.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    this.props.setUser(e.currentTarget.innerText, e.currentTarget.value);
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
  };

  render() {
    const {
      onChange,
      onClick,
      // onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;
    let stickySearchBar = "";
    
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className={classes.suggestions}>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = classes.suggestionActive;
              }

              return (
                <li
                  className={className}
                  key={index}
                  value={suggestion.error_id}
                  onClick={onClick}
                >
                  {suggestion.error_maker}
                </li>
              );
            })}
          </ul>
        );
      }
    }

    if(this.props.henrysMediaSearch){
      suggestionsListComponent = "";
      if(this.props.inputStyle === "scrolling"){
        stickySearchBar = classes.stickySearchBar;
      }
    }
    
    return (
      <div className={classes.autoCompleteContainer + " " + stickySearchBar}>
        <Fragment>
        <input
          type="text"
          onChange={onChange}
          // onKeyDown={onKeyDown}
          value={userInput}
          name="maker"
          placeholder={this.props.placeHolder}
          autoComplete="off"
        />
        {suggestionsListComponent}
        </Fragment>
      </div>
    );
  }
}

export default Autocomplete;