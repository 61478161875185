import React, { useEffect, useState } from 'react';
import classes from './HenrysRentalAdd.module.css';
import Axios from 'axios';
import CONST from '../../../constants/constants';
import FUNCTIONS from '../../../functions/functions';
import ImageUpload from '../../../components/Ui/ImageUpload/ImageUpload';
import Loading from '../../../components/Ux/LoadingScreen/LoadingScreen';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import GoBack from '../../../components/Ui/GoBack/GoBack';

export default function HenrysRentalAdd(props){

    const [henrysRentalFormData, setHenrysRentalFormData] = useState({
        mediaTypes: ["Blu-ray", "DVD", "VHS"],
        mediaType: "Select Media Type",
        mediaTitle: "",
        categoryId1: "Select Category",
        categoryId2: "Optional Second Category",
        categoryId3: "Optional Third Category",
        familyMember: "Select Family Member"
    });

    const [henrysRentalLoadingScreen, setHenrysRentalLoadingScreen] = useState({
        loadingScreen: ""
    });

    const [henrysRentalCategory, setHenrysRentalCategory] = useState({
        categories: [],
        familyMembers: [],
        henryImages: []
    });

    const [henrysRentalFormDataErrors, setHenrysRentalFormDataErrors] = useState({
        mediaTitleError: "",
        mediaTypeError: "",
        categoryId1Error: "",
        imageFrontError: "",
        imageBackError: "",
        familyMemberError: ""
    });

    const [henrysRentalImageFront, setHenrysRentalImageFront] = useState({
        imageFront: "",
        imageFrontPreviewUrl: "",
    });

    const [henrysRentalImageBack, setHenrysRentalImageBack] = useState({
        imageBack: "",
        imageBackPreviewUrl: "",
    });

    const [henrysRentalSuccessMessage, setHenrysRentalSuccessMessage] = useState({
        redirect: false
    });

    useEffect(() => {
        Axios.defaults.withCredentials = true;
        Axios.get(CONST.BASE_URL + '/api/henrys-rental-get-categories').then(response =>{
            setHenrysRentalCategory({
                categories: response.data.categories,
                familyMembers: response.data.familyMembers,
                henryImages: response.data.henrysImages
            })
        })
      }, []);
    
      const changeHandler = (e) => setHenrysRentalFormData(
        (prevState) => (
            { 
                ...prevState, [e.target.name]: e.target.value 
            }
        )
    );

    function removeLoadingScreen(time, message) {
        setTimeout(function() {
            resetAndRedirect(message);
        }, time);
      }

    function resetAndRedirect(message){
        setHenrysRentalLoadingScreen({
            loadingScreen: ""
        })
        setHenrysRentalSuccessMessage({
            redirect: <Redirect to={{
                pathname: "/henrys-rental",
                state: { fromRedirect: message }
                }}                  
            />
        })
    }

    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

    const submitNewMediaHandler = function(event){
        event.preventDefault(henrysRentalFormData);

        let mediaTitleError = "";
        let mediaTypeError = "";
        let categoryId1Error = "";
        let imageFrontError = "";
        let imageBackError = "";
        let familyMemberError = "";

        if(FUNCTIONS.checkAllowedTextInput(henrysRentalFormData.mediaTitle, "Title")){
            mediaTitleError = FUNCTIONS.checkAllowedTextInput(henrysRentalFormData.mediaTitle, "Title");
        }

        if(henrysRentalFormData.categoryId1 === "Select Category"){
            categoryId1Error = <h4 className="error">Please select at least one category</h4>;
        }

        if(henrysRentalFormData.mediaType === "Select Media Type"){
            mediaTypeError = <h4 className="error">Please select a media type</h4>;
        }

        if(henrysRentalFormData.familyMember === "Select Family Member"){
            familyMemberError = <h4 className="error">Please select who has the media</h4>;
        } 

        if(henrysRentalImageFront.imageFront === ""){
            imageFrontError = <h4 className="error">Image for front of the {henrysRentalFormData.mediaType} required</h4>;
        }

        if(henrysRentalImageBack.imageBack === ""){
            imageBackError = <h4 className="error">Image for back of the {henrysRentalFormData.mediaType} required</h4>;
        }
        

        if(!mediaTitleError && !mediaTypeError && !categoryId1Error && !imageFrontError && !imageBackError){
            setHenrysRentalLoadingScreen({
                loadingScreen: <Loading henrysImage={CONST.BASE_URL+"/storage/images/henrys-images/" + henrysRentalCategory.henryImages[randomIntFromInterval(0, henrysRentalCategory.henryImages.length - 1)].name + ".webp"} message="Adding New Media to Henrys Rental, please wait"/>
            })

            
            let fd = new FormData();
            fd.append('mediaType', henrysRentalFormData.mediaType);
            fd.append('mediaTitle', henrysRentalFormData.mediaTitle);
            fd.append('categoryId1', henrysRentalFormData.categoryId1);
            fd.append('categoryId2', henrysRentalFormData.categoryId2 !== "Optional Second Category" ? henrysRentalFormData.categoryId2: "");
            fd.append('categoryId3', henrysRentalFormData.categoryId3 !== "Optional Third Category" ? henrysRentalFormData.categoryId3: "");
            fd.append('familyMember', henrysRentalFormData.familyMember);
            fd.append('imageFront', henrysRentalImageFront.imageFront, henrysRentalImageFront.imageFront.name);
            fd.append('imageBack', henrysRentalImageBack.imageBack, henrysRentalImageBack.imageBack.name);
            Axios.defaults.withCredentials = true;
            Axios.post(CONST.BASE_URL + '/api/henrys-rental-add-media', fd).then(response =>{
                if(response.data.code === "success"){
                    // Reset State after successfull data addition
                setHenrysRentalFormData({
                    mediaTypes: ["Blu-ray", "DVD", "VHS"],
                    mediaType: "Select Media Type",
                    mediaTitle: "",
                    categoryId1: "Select Category",
                    categoryId2: "Optional Second Category",
                    categoryId3: "Optional Third Category",
                    familyMember: "Select Family Member"
                });
                // Reset Form Data Errors
                setHenrysRentalFormDataErrors({
                    mediaTitleError: "",
                    mediaTypeError: "",
                    categoryId1Error: "",
                    imageFrontError: "",
                    imageBackError: ""
                });
                setHenrysRentalImageFront({
                    imageFront: "",
                    imageFrontPreviewUrl: "",
                });
                setHenrysRentalImageBack({
                    imageBack: "",
                    imageBackPreviewUrl: "",
                });
                removeLoadingScreen(3000, response.data.message);
                } else if(response.data.code === "already_exists"){
                    setHenrysRentalLoadingScreen({
                        loadingScreen: ""
                    })
                    setHenrysRentalFormDataErrors({
                        mediaTitleError: <h4 className="error">{response.data.message}</h4>,
                        mediaTypeError: "",
                        categoryId1Error: "",
                        imageFrontError: "",
                        imageBackError: ""
                    });
                }
            })
        } else {
            setHenrysRentalFormDataErrors({
                mediaTitleError: mediaTitleError,
                mediaTypeError: mediaTypeError,
                categoryId1Error: categoryId1Error,
                imageFrontError: imageFrontError,
                imageBackError: imageBackError,
                familyMemberError: familyMemberError
            })
        }
    }

    const getData = function(imageArea, val, imageAreaPreviewUrl, imagePreviewUrl ,imageAreaError, imageError)
    {

        if(imageArea === "imageFront"){
            setHenrysRentalImageFront({
                [imageArea]: val,
                [imageAreaPreviewUrl]: imagePreviewUrl,
            })
        }

        if(imageArea === "imageBack"){
            setHenrysRentalImageBack({
                [imageArea]: val,
                [imageAreaPreviewUrl]: imagePreviewUrl,
            })
        }
        setHenrysRentalFormDataErrors({
            [imageAreaError]: imageError
        })
    }

    let category2Display = classes.notDisplayed;
    let category3Display = classes.notDisplayed;

    if(henrysRentalFormData.categoryId1 !== "Select Category"){
        category2Display = classes.displayed;
    }

    if(henrysRentalFormData.categoryId1 !== "Select Category" && henrysRentalFormData.categoryId2 !== "Optional Second Category"){
        category3Display = classes.displayed;
    }

    return(
        <div className={classes.HenrysRentalAdd}>
            {henrysRentalSuccessMessage.redirect}
            {henrysRentalLoadingScreen.loadingScreen}
            <form className={classes.henrysRentalAddForm}>
                <GoBack back={() => props.history.goBack()} />
                {henrysRentalSuccessMessage.message}
                <h2>Add Media to Henry's Rental</h2>
                <select
                        className={classes.input}
                        name="mediaType"
                        value={henrysRentalFormData.mediaType}
                        onChange={changeHandler}
                >
                    <option name="mediaType" value="Select Media Type">Select Media Type</option>
                    {henrysRentalFormData.mediaTypes.map((mediaType, i) =>
                        <option key={i} name="mediaType" value={mediaType}>{mediaType}</option>
                    )}
                </select>
                {henrysRentalFormDataErrors.mediaTypeError}
                <input
                    className={classes.input}
                    type="text"
                    name="mediaTitle"
                    placeholder="Title"
                    autoComplete="off"
                    value={henrysRentalFormData.mediaTitle || ''}
                    onChange={changeHandler}
                />
                {henrysRentalFormDataErrors.mediaTitleError}
                <h3>Select up to 3 categories</h3>
                <select
                    className={classes.input}
                    name="categoryId1"
                    value={henrysRentalFormData.categoryId1}
                    onChange={changeHandler}
                >
                    <option name="categoryId1" value="Select Category">Select Category</option>
                    {henrysRentalCategory.categories.map((category) =>
                        <option key={category.category_id} name="categoryId1" value={category.category_id}>{category.category}</option>
                    )}
                </select>
                {henrysRentalFormDataErrors.categoryId1Error}
                <select
                    className={category2Display}
                    name="categoryId2"
                    value={henrysRentalFormData.categoryId2}
                    onChange={changeHandler}
                >
                    <option name="categoryId2" value="Optional Second Category">Optional Second Category</option>
                    {henrysRentalCategory.categories.map((category) =>
                        <option key={category.category_id} name="categoryId2" value={category.category_id}>{category.category}</option>
                    )}
                </select>
                <select
                    className={category3Display}
                    name="categoryId3"
                    value={henrysRentalFormData.categoryId3}
                    onChange={changeHandler}
                >
                    <option name="categoryId3" value="Optional Third Category">Optional Third Category</option>
                    {henrysRentalCategory.categories.map((category) =>
                        <option key={category.category_id} name="categoryId3" value={category.category_id}>{category.category}</option>
                    )}
                </select>
                <h3>Who Has It?</h3>
                <select
                        className={classes.input}
                        name="familyMember"
                        value={henrysRentalFormData.familyMember}
                        onChange={changeHandler}
                >
                    <option name="familyMember" value="Select Family Member">Select Media Holder</option>
                    {henrysRentalCategory.familyMembers.map((familyMember) =>
                        <option key={familyMember.id} name="familyMember" value={familyMember.id}>{familyMember.name}</option>
                    )}
                </select>
                {henrysRentalFormDataErrors.familyMemberError}
                <ImageUpload 
                    imageNumber="imageFront" 
                    imageNumberError="imageFrontPreviewUrl" 
                    imagePreview="imageFrontPreview" 
                    imagePopulatedPreview={henrysRentalImageFront.imageFrontPreviewUrl} 
                    imageError={henrysRentalFormDataErrors.imageFrontError} wording="Add Front Image?" 
                    sendData={getData} 
                />
                <ImageUpload 
                    imageNumber="imageBack" 
                    imageNumberError="imageBackPreviewUrl" 
                    imagePreview="imageBackPreview" 
                    imagePopulatedPreview={henrysRentalImageBack.imageBackPreviewUrl} 
                    imageError={henrysRentalFormDataErrors.imageBackError} wording="Add Back Image?" 
                    sendData={getData} 
                />
                <button className="main-button" onClick={submitNewMediaHandler}>
                    Submit New Media
                </button>
            </form>
        </div>
    )
}