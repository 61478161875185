import Axios from 'axios';
import React, { Component } from 'react';
import classes from './SearchedBogeEntries.module.css';
import BogeEntrySnippet from '../../components/BogeEntrySnippet/BogeEntrySnippet';
import CONST from '../../constants/constants';
import GoBack from '../../components/Ui/GoBack/GoBack';
import FUNCTIONS from '../../functions/functions';

class SearchedBogeEntries extends Component {
    
    state = {
        bogeEntries: [],
        userName: ""
    }

    componentDidMount() {
        Axios.get(CONST.BASE_URL + '/api/searched-boge-entries/' + this.props.match.params.search).then(response => {
            this.setState({
                bogeEntries: response.data.bogeEntries
            }, () => {
                FUNCTIONS.handleOldScrollPosition();
              })
        })
    };
    
    render() {
        return(
            <div className={classes.Home}>
                <GoBack back={() => this.props.history.goBack()} />
                <h4>Displaying search results for: {this.props.match.params.search}</h4>
                {this.state.bogeEntries.map(bogeEntries =>
                    <BogeEntrySnippet
                        error={bogeEntries.grammar_error}
                        intention={bogeEntries.grammar_intention}
                        level={bogeEntries.error_level}
                        maker={bogeEntries.error_maker}
                        votes={bogeEntries.boge_entry_votes.length}
                        id={bogeEntries.id}
                        key={bogeEntries.id}
                    />
                )}
            </div>
        )
    }
}

export default SearchedBogeEntries