import Axios from 'axios';
import React, { Component } from 'react';
import styles from './EmailVerification.module.css';
import CONST from '../../../constants/constants';
import LoadingScreen from '../../Ux/LoadingScreen/LoadingScreen';

class EmailVerification extends Component {

    state = {
        verifyEmailStatus: false,
        loadingScreen: ""
    }

    onClickHandler = (event) => {
        event.preventDefault();
        this.setState({
            loadingScreen: <LoadingScreen message="Sending Verification email. Please wait" />
        })
        Axios.defaults.withCredentials = true;
        Axios.get(CONST.BASE_URL + '/api/email/resend').then(response => {
            if(response.data.message === "Email Sent"){
                this.setState({
                    verifyEmailStatus: true,
                    loadingScreen: ""
                })  
            }
        })
    }

    render(){

        let verifyEmailStatus = <button className="main-button" onClick={this.onClickHandler}>Resend Verification Email</button>;

        if(this.state.verifyEmailStatus){
            verifyEmailStatus = <h5 className="success">Email Sent! Follow the instructions within the email to verify your email address</h5>;
        }

        return(
            <div className={styles.EmailVerification}>
                {this.state.loadingScreen}
                <div className={styles.emailVerificationContainer}>
                    <h3>Please verify your email before you can use the features of this site.</h3>
                    <p>You should have been sent a verfication email after registering.</p>
                    <p>If you have not received this email please click the button below and you will be sent another:</p>
                    {verifyEmailStatus}
                </div>
            </div>
        )
    }
}

export default EmailVerification;