import { useEffect, useState } from 'react';
import classes from './ActiveQuizTeams.module.css';
import axios from 'axios';
import CONST from '../../../../constants/constants';
import { Redirect } from 'react-router-dom';

export default function ActiveQuizTeams(props){

    const [quizTeams, setQuizTeams] = useState({
        names: [],
        allAnswered: false,
        maximumPoints: "",
        allTeamsMarked: false,
        quizMarking: false
    });

    const [redirect, setRedirect] = useState({
        url: ""
    });

    const quizTeamNames = function(){
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/get-active-quiz-teams/' + props.quizId).then(response => {
            setQuizTeams({
                names: [response.data.teamDetails],
                allAnswered: response.data.allTeamsAnswered,
                maximumPoints: response.data.maximum_points,
                allTeamsMarked: response.data.allTeamsMarked,
                quizMarking: response.data.quizMarking
            })
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    const sendAnswerswForMarking = function(){
        axios.defaults.withCredentials = true;
        axios.post(CONST.BASE_URL + '/api/send-all-answers-for-marking/' + props.quizId).then(response => {
            setRedirect({
                url:    <Redirect to={{
                            pathname: "/answers-to-mark/" + props.match.params.id
                        }}                  
                    />
            })
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    const completeAndReveal = function(){
        axios.defaults.withCredentials = true;
        axios.post(CONST.BASE_URL + '/api/complete-quiz-and-reveal-scores', {quizId: props.quizId}).then(response => {
            props.startingValues();
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    useEffect(() => {
        let refreshTeamNames = setInterval(quizTeamNames, 3000);
        // When leave the page clear the intervals
        return () => {
            clearInterval(refreshTeamNames);
        }
    }, []);

    let teamNames = <p>Waiting for teams.........</p>

    if(quizTeams.names.length > 0){
        let teamsSorted = quizTeams.names[0].sort((a, b) => b.score - a.score);

        if(teamsSorted.length > 0){
            if(teamsSorted[0]){
                teamNames = 
                <div className={classes.teamNamesContainer}>
                    {quizTeams.names[0].map((name, i) =>
                    <div className={classes.teamContainer} key={i}>
                        <p>{name.name}</p>
                        <p>{name.team_members}</p>
                        <p>{name.number_of_answers} / {name.number_of_questions}</p>
                        <p>{name.marking_team}</p>
                        <p>{name.number_of_marked_answers} / {name.number_of_questions}</p>
                        <p>{name.score}</p>
                    </div>
                    )}
                </div>
            }
        }
    }

    let status = <h1>Status - quiz questions in progress</h1>;

    if(props.completed){
        status = <h1>This quiz has now been completed</h1>;
    } else if(quizTeams.quizMarking){
        if(quizTeams.allTeamsMarked){
            status = <button onClick={completeAndReveal} className='main-button'>Complete and Reveal</button>
        } else {
            status = <h1>Status - Currently being Marked</h1>;
        }
    } else if(quizTeams.allAnswered){
        status = <button onClick={sendAnswerswForMarking} className='main-button'>Send Answers for Marking</button>
    }

    return(
        <div className={classes.activeQuizTeams}>
            <div className={classes.teamTitlesContainer}>
                <h3>Team Name</h3>
                <h3>Team Members</h3>
                <h3>A/Q</h3>
                <h3>Marking this team</h3>
                <h3>M/Q</h3>
                <h3>Scores / {quizTeams.maximumPoints}</h3>
            </div>
            {teamNames}
            {status}
        </div>
    )
}