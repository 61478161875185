import React from 'react';
import classes from './SideDrawer.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';
import Backdrop from '../../../Ux/Backdrop/Backdrop';
import Aux from '../../../../hoc/Auxilary/Auxilary';

const sideDrawer = (props) => {

    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }

    return(
        <Aux>
            <Backdrop show={props.open} clicked={props.drawToggleClicked} />
            <div className={attachedClasses.join(' ')}>
                <NavigationItems clicked={props.drawToggleClicked} />
            </div>
        </Aux>  
    )
    
}

export default sideDrawer