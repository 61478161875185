import { useEffect, useState } from 'react';
import classes from './TeamsToMarkWaitingRoom.module.css';
import axios from 'axios';
import CONST from '../../../constants/constants';
import { Redirect } from 'react-router-dom';

export default function TeamsToMarkWaitingRoom(props){


    const [answers, setAnswer] = useState({
        teamToMark: <h1>Finding a team to mark. Please wait.....</h1> 
    });

    const [redirect, setRedirect] = useState({
        url: ""
    });

    const quizTeamToMark = function(){
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/get-answers-to-mark/' + props.match.params.id).then(response => {
            if(response.data.finalAnswersToMark.length > 0){
                setAnswer({
                    teamToMark: <h1>Found Answers to Mark</h1>
                })
                setRedirect({
                    url:    <Redirect to={{
                                pathname: "/answers-to-mark/" + props.match.params.id
                            }}                  
                        />
                })
            }
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    useEffect(() => {
        let refreshTeamNames = setInterval(quizTeamToMark, 3000);
        return () => {
            clearInterval(refreshTeamNames);
        }
    }, []);

    return(
        <div className={classes.teamsToMarkWaitingRoom}>
            {redirect.url}
            {answers.teamToMark}
        </div>
    )
}