import classes from './AnswersToMark.module.css';
import axios from 'axios';
import CONST from '../../../constants/constants';
import { useEffect, useState } from 'react';
import AnswerToMark from './AnswerToMark/AnswerToMark';
import { Redirect } from 'react-router-dom';

export default function AnswersToMark(props){

    const [answers, setAnswers] = useState({
        answers: [] 
    });

    const [redirect, setRedirect] = useState({
      url: ""
  });

    useEffect(() => {
        startingValues();
    }, []);

    const startingValues = function(){
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/get-answers-to-mark/' + props.match.params.id).then(response => {
          if(response.data.allAnswersMarked){
            setRedirect({
                url:    <Redirect to={{
                            pathname: "/results-waiting-room/" + props.match.params.id
                        }}                  
                    />
            })
          } else {
            setAnswers({
              answers: response.data.finalAnswersToMark
            })
          }
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    return(
        <div className={classes.answersToMark}>
          <h1>Please mark you designated answers</h1>
          {redirect.url}
          {answers.answers.map((answer, i) =>
            <AnswerToMark key={i} answerNumber={i + 1} answerToMark={answer.teamToMarkAnswer} teamAnswer={answer.teamAnswer} startingValues={startingValues}/>
          )}
        </div>
    )
}