import { useEffect, useState } from 'react';
import classes from './CompletedQuiz.module.css';
import axios from 'axios';
import CONST from '../../../constants/constants';
import { FaTrophy } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Redirect } from 'react-router-dom';
import ConfirmDelete from '../../../components/Ui/ConfirmDelete/ConfirmDelete';

const user = JSON.parse(localStorage.getItem("user"));

export default function CompletedQuiz(props){

    const [results, setResults] = useState({
        results: "",
        quiz: ""
    });

    const [redirect, setRedirect] = useState({
        url: ""
    });

    const [confirmDelete, setConfirmDelete] = useState({
        shown: false
    });

    const revealResults = function(){
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/get-quiz-results/' + props.match.params.id).then(response => {
            if(response.data.quiz.completed){
                setResults({
                    results: response.data.results,
                    quiz: response.data.quiz.name
                })
            }
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    useEffect(() => {
        revealResults();
    }, []);

    function getNumberWithOrdinal(n) {
        var s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      }

    function deleteQuizHandler(){
        axios.defaults.withCredentials = true;
        axios.delete(CONST.BASE_URL + '/api/delete-quiz/' + props.match.params.id).then(response => {
            setRedirect({
                url:    <Redirect to={{
                            pathname: "/quiz-landing-page"
                        }}                  
                    />
            })
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    const confirmDeleteHandler = function(id){
        setConfirmDelete({
            shown: !confirmDelete.shown
        })
    }

    let finalResults = "";
    let confirmDeleteModal = "";
    let deletQuizButton = "";

    if(confirmDelete.shown){
        confirmDeleteModal = <ConfirmDelete delete={deleteQuizHandler} clicked={confirmDeleteHandler}/>;
    }

    if(results.results.length > 0){
        let resultsSorted = results.results.sort((a, b) => b.score - a.score);

        if(resultsSorted.length > 0){
            if(resultsSorted[0]){
                finalResults = 
                <div className={classes.resultsContainer}>
                    <h1>{results.quiz}</h1>
                    <h2>Final Standings</h2>
                    {results.results.map((name, i) =>
                    <div className={classes.resultContainer} key={i}>
                            {i+1 === 1 ? <div className={classes.championsContainer}><FaTrophy /> <p>Champion</p> <FaTrophy /></div> : ""}
                            <div className={classes.postionContainer}>
                                <h2>{getNumberWithOrdinal(i+1)}</h2>
                                <p>{name.name}</p>
                                <p>{name.team_members}</p>
                                <p>{name.score}</p>
                            </div>
                    </div>
                    )}
                </div>
            }
        }
    }

    if (user.admin) {
        deletQuizButton = <MdDelete onClick={confirmDeleteHandler} className={classes.deleteQuiz + " delete"}/>   
    }

    return(
        <div className={classes.completedQuiz}>
            {redirect.url}
            {finalResults}
            {deletQuizButton}
            {confirmDeleteModal}
        </div>
    )
}