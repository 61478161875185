import React, { Component } from 'react';
import classes from './BogeEntryForm.module.css';
import Autocomplete from '../Ui/AutoComplete/KnownCulprits';

class BogeEntryForm extends Component {

    getData = (val, id) => {
        this.props.sendData(val, id);
    }

    render(){

    if (this.props.newBogeEntry){

        let submitButton = classes.submitButtonHidden;

        if(this.props.error && this.props.intention && this.props.maker) {
            submitButton = "main-button";
        }
        return(
            <form className={classes.BogeEntryForm}>
                <h3>Please fill in your Boge entry below:</h3>
                <textarea
                    type="text"
                    name="error"
                    placeholder="Boge Entry"
                    autoComplete="off"
                    value={this.props.error}
                    onChange={this.props.changeHandler}
                />
                {this.props.errorErrorMessage}
                <textarea
                    type="text"
                    name="intention"
                    placeholder="Intention"
                    autoComplete="off"
                    value={this.props.intention}
                    onChange={this.props.changeHandler}
                />
                {this.props.intentionErrorMessage}
                <Autocomplete
                    placeHolder={"Culprit"}
                    suggestions={this.props.knownCulprits}
                    sendData={this.getData}
                    setUser={this.getData}
                />
                {this.props.makerErrorMessage}
                <textarea
                    type="text"
                    name="context"
                    placeholder="Context (leave blank if this is not needed)"
                    autoComplete="off"
                    value={this.props.context}
                    onChange={this.props.changeHandler}
                />
                {this.props.contextErrorMessage}
                <button className={submitButton} onClick={this.props.submit}>submit</button>
            </form>
        )
    } else {
        return(
            <form className={classes.BogeEntryForm}>
                <h3>Edit Boge Entry here:</h3>
                <textarea
                    type="text"
                    name="error"
                    autoComplete="off"
                    value={this.props.error}
                    onChange={this.props.changeHandler}
                />
                {this.props.errorErrorMessage}
                <textarea
                    type="text"
                    name="intention"
                    autoComplete="off"
                    value={this.props.intention}
                    onChange={this.props.changeHandler}
                />
                {this.props.intentionErrorMessage}
                <input
                    type="text"
                    name="maker"
                    autoComplete="off"
                    value={this.props.maker}
                    onChange={this.props.changeHandler}
                />
                {this.props.makerErrorMessage}
                <textarea
                    type="text"
                    name="context"
                    placeholder="Context (leave blank if this is not needed)"
                    autoComplete="off"
                    value={this.props.context}
                    onChange={this.props.changeHandler}
                />
                {this.props.contextErrorMessage}
                <button className="main-button" onClick={this.props.submit}>update</button>
            </form>
        )
    }
    }
}

export default BogeEntryForm
