import { useEffect, useState } from "react";
import classes from './CurrentlyPlayingQuiz.module.css';
import axios from 'axios';
import CONST from '../../../constants/constants';
import CurrentlyPlayingQuizAnswer from "./CurrentlyPlayingQuizAnswer/CurrentlyPlayingQuizAnswer";
import { Redirect } from 'react-router-dom';

export default function CurrentlyPlayingQuiz(props){

    const [question, setQuestion] = useState({
        question: "",
        number: ""
    });

    const [redirect, setRedirect] = useState({
        url: ""
    });

    const [quiz, setQuiz] = useState({
        name: "",
        numberOfQuestions: "",
        code: "",
        id: ""
    });

    

    const startingValues = function(){
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/get-single-active-quiz-user/' + props.match.params.id).then(response => {
            if(response.data.allQuestionsAnswered){
                setRedirect({
                    url:    <Redirect to={{
                                pathname: "/teams-to-mark-waiting-room/" + response.data.singleActiveQuiz.id
                            }}                  
                        />
                })
            } else {
                setQuiz({
                    name: response.data.singleActiveQuiz.name,
                    numberOfQuestions: response.data.singleActiveQuiz.quiz_questions.length,
                    code: response.data.singleActiveQuiz.quiz_code,
                    id: response.data.singleActiveQuiz.id
                })
                setQuestion({
                    question: response.data.question,
                    number: response.data.question.question_number
                })
            }
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    useEffect(() => {
        startingValues();
    }, []);

    return(
        <div className={classes.currentlyPlayingQuiz}>
            {redirect.url}
            <div className={classes.currentlyPlayingQuizContainer}>
                <h2 className={classes.quizCode}>Quiz Code: {quiz.code}</h2>
                <h1>{quiz.name}</h1>
                <CurrentlyPlayingQuizAnswer quizId={quiz.id} questionNumber={question.number} question={question.question} startingValues={startingValues}/>
            </div>
        </div>
    )
}