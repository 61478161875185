import Axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import styles from './BogeEntrySingle.module.css';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineStop } from "react-icons/ai";
import { RiSendPlane2Fill } from "react-icons/ri";
import Moment from 'react-moment';
import CONST from '../../constants/constants';
import BogeEntryComment from '../../components/BogeEntryComment/BogeEntryComment';
import BogeEntryErrorLevel from '../../components/BogeEntrySnippet/BogeEntryErrorLevel/BogeEntryErrorLevel';
import ConfirmDelete from '../../components/Ui/ConfirmDelete/ConfirmDelete';
import GoBack from '../../components/Ui/GoBack/GoBack';
import BogeEntryVotingControl from './BogeEntryVotingControl/BogeEntryVotingControl';
import FUNCTIONS from '../../functions/functions';
import FlashMessage from '../../components/Ui/FlashMessage/FlashMessage';

class BogeEntrySingle extends Component {

    state = {
        error_level: '',
        error_maker: '',
        error_poster: '',
        grammar_error: '',
        grammar_intention: '',
        context: '',
        votes: '',
        created_at: '',
        error_user_id: '',
        avatar: '',
        redirect: false,
        error_level_vote: 1,
        userAlreadyVoted: true,
        votingButtonVisable: false,
        bogeEntryComments: [],
        bogeEntryComment: '',
        commentErrorMessage: '',
        confirmDelete: '',
        open: false
    }

    componentDidMount(){
        let userAlreadyVoted = this.state.userAlreadyVoted;
        Axios.defaults.withCredentials = true;
        FUNCTIONS.scrollToTop();
        Axios.get(CONST.BASE_URL + '/api/boge-entry-single/' + this.props.match.params.id).then(response => {
            if(response.data.voteResponse){
                userAlreadyVoted = response.data.voteResponse;
            } else {
                userAlreadyVoted = false
            }
            this.setState({
                userAlreadyVoted: userAlreadyVoted,
                error_level: response.data.singleBogeEntry.error_level,
                error_maker: response.data.singleBogeEntry.error_maker,
                error_poster: response.data.singleBogeEntry.boge_entry_user.name,
                grammar_error: response.data.singleBogeEntry.grammar_error,
                grammar_intention: response.data.singleBogeEntry.grammar_intention,
                context: response.data.singleBogeEntry.context,
                votes: response.data.singleBogeEntry.votes,
                created_at: response.data.singleBogeEntry.created_at,
                error_user_id: response.data.singleBogeEntry.boge_entry_user.id,
                avatar: response.data.singleBogeEntry.boge_entry_user.avatar,
                bogeEntryComments: response.data.bogeEntryComments
            })
        })
    }

    errorLevelVoteChangeHandler = (event) => {
        this.setState({
            error_level_vote: event.target.value,
            votingButtonVisable: true
        })
    }

    submitVoteHandler = (event) => {
        event.preventDefault();
        Axios.patch(CONST.BASE_URL + '/api/update-boge-entry-error-level', {
            boge_entry_id: this.props.match.params.id,
            error_vote_level: this.state.error_level_vote
        }).then(response =>{
            this.setState({
                error_level: response.data.updated_error_level,
                votes: response.data.number_of_votes,
                userAlreadyVoted: true
            })
        })
    }

    removeConfirmDeleteHandler = () => {
        this.setState({
            confirmDelete: "",
            open: false
        })
    }

    confirmDeleteHandler = (id) => {
        this.setState({
            open: true
        })
        if(Number.isInteger(id)){
            this.setState({
                confirmDelete: <ConfirmDelete delete={this.deleteCommentHandler.bind(this, id)} clicked={this.removeConfirmDeleteHandler} />
            })
        } else {
            this.setState({
                confirmDelete: <ConfirmDelete delete={this.deleteBogeEntryHandler} clicked={this.removeConfirmDeleteHandler} />
            })
        }
    }

    deleteCommentHandler = (id) => {
        Axios.defaults.withCredentials = true;
        Axios.delete(CONST.BASE_URL + '/api/delete-comment/' + id).then(response => {
            this.setState({
                open: false,
                bogeEntryComments: response.data.bogeEntryComments,
                confirmDelete: ''
            })
        })
    }

    deleteBogeEntryHandler = () => {
        Axios.defaults.withCredentials = true;
        Axios.delete(CONST.BASE_URL + '/api/delete-boge-entry/' + this.props.match.params.id).then(response => {
            if(response.data.message === "successfully deleted"){
                this.setState({
                    open: false,
                    redirect: <Redirect to="/" />
                })
            }
        })
    }

    // Comments Function
    commentChangeHandler = (event) => {
        this.setState({
            bogeEntryComment: event.target.value,
            commentErrorMessage: ""
        })
    }

    submitCommentHandler = (event) => {
        if(JSON.parse(localStorage.getItem("user"))) {
            event.preventDefault();
            let commentErrorMessage = "";

            if(this.state.bogeEntryComment === ""){
                commentErrorMessage = <h4 className={styles.errorMessage + " error"}>Your comment cannot be empty</h4>;
            } else if(this.state.bogeEntryComment.length < 6) {
                commentErrorMessage = <h4 className={styles.errorMessage + " error"}>Your comment must be longer than 5 character</h4>;
            }

            if(!commentErrorMessage) {
                Axios.defaults.withCredentials = true;
                Axios.post(CONST.BASE_URL + "/api/new-boge-entry-comment", {
                    boge_entry_id: this.props.match.params.id,
                    userComment: this.state.bogeEntryComment
                }).then(response => {
                    this.setState({
                        bogeEntryComments: response.data.bogeEntryComments,
                        bogeEntryComment: ''
                    })
                })
            } else {
                this.setState({
                    commentErrorMessage: commentErrorMessage
                })
            }
        } else {
            this.setState({
                redirect: <Redirect to={{
                    pathname: "/login",
                    state: { fromRedirect: "You Must be logged into to use this feature" }
                    }}                  
                />
            })
        }
    }

    requestDeleteCommentHandler = (id) => {

        this.confirmDeleteHandler(id);
    }

    likeOrDislikeHandler = (id, choice) => {
        if(JSON.parse(localStorage.getItem("user"))) {
            Axios.defaults.withCredentials = true;
            Axios.post(CONST.BASE_URL + "/api/like-or-dislike", {
                choice: choice,
                id: id
            }).then(response => {
                this.setState({
                    bogeEntryComments: response.data.bogeEntryComments
                })
            })
        } else {
            this.setState({
                redirect: <Redirect to={{
                    pathname: "/login",
                    state: { fromRedirect: "You Must be logged into to use this feature" }
                    }}                  
                />
            })
        }
    }

    deleteLikeOrDislikeHandler = (id, choice) => {
        if(JSON.parse(localStorage.getItem("user"))) {
            Axios.defaults.withCredentials = true;
            Axios.delete(CONST.BASE_URL + "/api/" + choice + "/" + id).then(response => {
                this.setState({
                    bogeEntryComments: response.data.bogeEntryComments
                })
            })
        } else {
            this.setState({
                redirect: <Redirect to={{
                    pathname: "/login",
                    state: { fromRedirect: "You Must be logged into to use this feature" }
                    }}                  
                />
            })
        }
    }

    render() {
        // Check if a user is logged in
        let editDeleteContainer = "";
        let votingFunction = this.state.userAlreadyVoted;
        let avatar = "";
        let error_level = this.state.error_level;
        let context = this.state.context;
        let loggedInUser = false;
        let addCommentSection = "";

        if(this.state.avatar){
            avatar = <img src={CONST.BASE_URL + "/storage/images/avatar-images/" + this.state.avatar} alt="" />
        }

        let postedBy = <div className={styles.poster}>
                            <h6>Posted By:</h6>
                            {avatar}
                            <Link to={'/profile' + this.state.error_user_id}>{this.state.error_poster} | <Moment date={this.state.created_at} format="lll"></Moment></Link>
                        </div>

        if(context){
            context = <div className={styles.intentionContainer}>
                          <h4>Context:</h4>
                          <h3 className={styles.intention}>{context}</h3>
                      </div>
        } else {
            context = "";
        }

        error_level = error_level.replace(/\.0$/,'');
        
        // Check if user is logged in and if so have they voted yet
        if(JSON.parse(localStorage.getItem("user"))){
            loggedInUser = JSON.parse(localStorage.getItem("user")).id;
            if(!this.state.userAlreadyVoted){
                votingFunction = <BogeEntryVotingControl error_level_vote={this.state.error_level_vote} slideVote={this.errorLevelVoteChangeHandler} votingButtonVisable={this.state.votingButtonVisable} submitVoteHandler={this.submitVoteHandler}/>    
            } else if(this.state.userAlreadyVoted === 1) {
                votingFunction = <div className={styles.culpritMessage}><h3>You cannot vote as you posted this Boge entry</h3></div>;
            } else if(this.state.userAlreadyVoted === 2){
                votingFunction = <div className={styles.culpritMessage}><h3>You cannot vote as you are the culprit</h3><AiOutlineStop /></div>;
            } else {
                votingFunction = <div className={styles.culpritMessage}><h3>{this.state.userAlreadyVoted}</h3></div>;
            }
            // If user is logged in and they match the user whom created the error only then can they edit
            if(JSON.parse(localStorage.getItem("user")).id === this.state.error_user_id){
                if(this.state.votes){
                    editDeleteContainer = <div className={styles.editDeleteContainer}><div className="delete" onClick={this.confirmDeleteHandler}><AiOutlineDelete /></div></div>;    
                } else {
                    editDeleteContainer = <div className={styles.editDeleteContainer}><Link to={"/edit-boge-entry" + this.props.match.params.id} className="edit"><AiOutlineEdit /></Link><div className="delete" onClick={this.confirmDeleteHandler}><AiOutlineDelete /></div></div>;
                }

                postedBy = <div className={styles.poster}>
                            <Link to={'/profile' + this.state.error_user_id}><h6>Posted By: </h6>You | <Moment date={this.state.created_at} format="lll"></Moment></Link>
                            {editDeleteContainer}
                        </div>
            }
            // Check if user is an admin user
            if(JSON.parse(localStorage.getItem("user")).admin){
                if(this.state.votes){
                    editDeleteContainer = <div className={styles.editDeleteContainer}><div className="delete" onClick={this.confirmDeleteHandler}><AiOutlineDelete /></div></div>;    
                } else {
                    editDeleteContainer = <div className={styles.editDeleteContainer}><Link to={"/edit-boge-entry" + this.props.match.params.id} className="edit"><AiOutlineEdit /></Link><div className="delete" onClick={this.confirmDeleteHandler}><AiOutlineDelete /></div></div>;
                }
                postedBy = <div className={styles.poster}>
                            <h6>Posted By:</h6>
                            {avatar}
                            <Link to={'/profile' + this.state.error_user_id}>{this.state.error_poster} | <Moment date={this.state.created_at} format="lll"></Moment></Link>
                            {editDeleteContainer}
                        </div>
            }
        addCommentSection = <div className={styles.commentInputContainer}>
                                        <textarea value={this.state.bogeEntryComment} onChange={this.commentChangeHandler} className={styles.commentInput} placeholder="Add New Comment Here..."/>
                                        <div className={styles.commentControlsContainer}>
                                            <RiSendPlane2Fill onClick={this.submitCommentHandler}/>
                                        </div>
                                        {this.state.commentErrorMessage}
                                    </div>
        }
        
        let successMsg = "";

        let showSuccessMsg = (message) => {
            successMsg = (
                <FlashMessage message={message} duration={3000}/>
            );
        };

        if (this.props.location.state !== undefined) {
            const message = this.props.location.state.fromRedirect;
            if (message) {
                showSuccessMsg(message);
            }
        } else if(this.state.userAlreadyVoted) {
            showSuccessMsg("Thank you for your vote");
        } else {
            
        }  
        
        if(this.state.open){
            document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'visible';
        }

        return(
            <div className={styles.BogeEntrySingleContainer}>
                <GoBack back={() => this.props.history.goBack()} />
                {this.state.redirect}
                {this.state.confirmDelete}
                <div className={styles.BogeEntryDetails}>
                <div className={styles.errorContainer}>
                    <h1 className={styles.error}>"{this.state.grammar_error}"</h1>
                    <h2 className={styles.maker}>{this.state.error_maker}</h2>
                </div>
                <div className={styles.intentionContainer}>
                    <h4>Translation:</h4>
                    <h3 className={styles.intention}>{this.state.grammar_intention}</h3>
                </div>
                {context}
                {votingFunction}
                {successMsg}
                <BogeEntryErrorLevel singlePage={true} error_level={error_level} votes={this.state.votes}/>
                {postedBy}
                <h3 className={styles.commentsTitle}>Comments ( {this.state.bogeEntryComments.length} )</h3>
                </div>    
                <div className={styles.allCommentsContainer}>
                    {addCommentSection}
                    {this.state.bogeEntryComments.map(bogeEntryComment =>
                        <BogeEntryComment
                            clicked={this.likeOrDislikeHandler}
                            removeLikeOrDislike={this.deleteLikeOrDislikeHandler}
                            deleteComment={this.requestDeleteCommentHandler.bind(this, bogeEntryComment.id)}
                            comment={bogeEntryComment.comment}
                            posted={bogeEntryComment.created_at}
                            poster={bogeEntryComment.user.name}
                            avatar={bogeEntryComment.user.avatar}
                            userId={bogeEntryComment.user.id}
                            likes={bogeEntryComment.likes}
                            dislikes={bogeEntryComment.dislikes}
                            id={bogeEntryComment.id}
                            key={bogeEntryComment.id}
                            loggedInUserId={loggedInUser}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(BogeEntrySingle)
