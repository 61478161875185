import { useEffect, useState } from "react";
import classes from './CurrentlyPlayingQuizAnswer.module.css';
import axios from 'axios';
import CONST from '../../../../constants/constants';

export default function CurrentlyPlayingQuizAnswer(props){

    const [answer, setAnswer] = useState({
        answer: ""
    });

    const [question, setQuestion] = useState({
        shown: true
    });

    const [counter, setCounter] = useState(35);

    useEffect(() => {
        const timer = setTimeout(() => setCounter(counter - 1), 1000);
        // counter > 0 && timer;
        return () => {
            clearTimeout(timer);
          };
    }, [counter]);

    useEffect(()=>{
        if(counter === 0){
            answerHandler();
        }
      },[counter])

    const quizAnswerChangeHandler = function(event){
        setAnswer({
            answer: event.target.value
        })
    }

    const answerHandler = function(){

        setQuestion({
            shown: false
        })

        axios.defaults.withCredentials = true;
        axios.post(CONST.BASE_URL + '/api/post-quiz-question-answer', {quiz_answer: answer.answer, quiz_question: props.question.id, quiz_id: props.quizId}).then(response => {
            setCounter(30)
            setTimeout(function() {
                setAnswer({
                    answer: ""
                })
                setQuestion({
                    shown: true
                })
                props.startingValues();
            }, 500)
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    let submitButton = <button onClick={answerHandler} className={classes.answerButton + " error"}>pass</button>;

    if(answer.answer.length > 2){
        submitButton = <button onClick={answerHandler} className={classes.answerButton + " success"}>submit</button>
    }

    let answerContainer = <div className={classes.answerContainer}>
                            <textarea
                                className={classes.teamName}
                                type="text"
                                name="quizCode"
                                placeholder="Answer Here"
                                autoComplete="off"
                                value={answer.answer}
                                onChange={quizAnswerChangeHandler}
                                onPaste={(e)=>{
                                    e.preventDefault()
                                    return false;
                                }}
                                onDrop={(e)=>{
                                    e.preventDefault()
                                    return false;
                                }}
                            />
                        </div>
    let answerControlsContainer = 
    <div className={classes.controlsContainer}>
        {submitButton}
    </div>

    if(answer.quiz_question === props.question.id){
        answerContainer = 
            <div className={classes.answerContainer}>
                <div className={classes.answeredContainer}>
                    <p>You answered:</p>
                    <p>{answer.answer}</p>
                </div>
            </div>;
        answerControlsContainer = "";
    }

    let questionShown = classes.currentlyPlayingQuizAnswer;

    if(!question.shown){
        questionShown = classes.currentlyPlayingQuizAnswerRemove
    }

    return(
        <div className={questionShown}>
            <h2>{counter}</h2>
            <h2 className={classes.questionNumber}>{props.questionNumber}</h2>
            <h2 className={classes.questionPointsValue}>Points Value {(props.question.bonus_questions + 1)}</h2>
            {answerContainer}
            {answerControlsContainer}
        </div>
    )
}