import React, { useEffect, useState } from 'react';
import classes from './HenrysRentalAddImages.module.css';
import ImageUpload from '../../../components/Ui/ImageUpload/ImageUpload';
import Axios from 'axios';
import CONST from '../../../constants/constants';
import GoBack from '../../../components/Ui/GoBack/GoBack';
import { ImCross } from "react-icons/im";
import ConfirmDelete from '../../../components/Ui/ConfirmDelete/ConfirmDelete';

export default function HenrysRentalAddImages(props){

    const [henrysRentalImage, setHenrysRentalImage] = useState({
        henrysImage: "",
        henrysImagePreviewUrl: "",
    });

    const [henrysImageImageErrors, setHenrysImageImageErrors] = useState({
        error: ""
    });

    const [henrysRentalImages, setHenrysRentalImages] = useState({
        images: []
    });

    const [henrysRentalMediaConfirmDelete, setHenrysRentalMediaConfirmDelete] = useState({
        shown: false,
        id: ""
    });

    useEffect(() => {
        loadStartingImages();
      }, []);

    const loadStartingImages = function(){
        Axios.defaults.withCredentials = true;
        Axios.get(CONST.BASE_URL + '/api/henrys-rental-get-all-images').then(response =>{
            setHenrysRentalImages({
                images: response.data
            })
        })
    }

    const getData = function(imageArea, val, imageAreaPreviewUrl, imagePreviewUrl ,imageAreaError, imageError)
    {
        setHenrysRentalImage({
            [imageArea]: val,
            [imageAreaPreviewUrl]: imagePreviewUrl,
        })

        setHenrysImageImageErrors({
            [imageAreaError]: imageError
        })
    }

    const submitHenrysImageHandler = function(){
        let fd = new FormData();
        fd.append('henrysImage', henrysRentalImage.henrysImage, henrysRentalImage.henrysImage.name);
        Axios.defaults.withCredentials = true;
        Axios.post(CONST.BASE_URL + '/api/henrys-rental-add-image', fd).then(response =>{
            setHenrysRentalImage({
                henrysImage: "",
                henrysImagePreviewUrl: "",
            });
            setHenrysImageImageErrors({
                error: ""
            });
            loadStartingImages();
        })
    }

    const confirmDeleteHandler = function(id){
        setHenrysRentalMediaConfirmDelete({
            shown: !henrysRentalMediaConfirmDelete.shown,
            id: id
        })
    }

    const deleteHandler = function(){
        Axios.defaults.withCredentials = true;
        Axios.delete(CONST.BASE_URL + '/api/delete-henrys-rental-image/' + henrysRentalMediaConfirmDelete.id).then(response =>{
            
        })
        setHenrysRentalImage({
            henrysImage: "",
            henrysImagePreviewUrl: "",
        });
        setHenrysImageImageErrors({
            error: ""
        });
        setHenrysRentalMediaConfirmDelete({
            shown: !henrysRentalMediaConfirmDelete.shown,
            id: ""
        })
        loadStartingImages();
    }

    let button = "";

    if(henrysRentalImage.henrysImage){
        button =    <button className="main-button" onClick={submitHenrysImageHandler}>
                        Add New Image
                    </button>
    }

    let confirmDelete = "";

    if(henrysRentalMediaConfirmDelete.shown){
        confirmDelete = <ConfirmDelete delete={deleteHandler} clicked={confirmDeleteHandler}/>;
    }

    return(
        <div className={classes.henrysRentalAddImages}>
            <GoBack back={() => props.history.goBack()} />
            {confirmDelete}
            <div className={classes.uploadControlsContainer}>
                <h1>Current Images</h1>
                <ImageUpload 
                    imageNumber="henrysImage" 
                    imageNumberError="henrysImagePreviewUrl" 
                    imagePreview="henrysImagePreview" 
                    imagePopulatedPreview={henrysRentalImage.henrysImagePreviewUrl} 
                    imageError={henrysImageImageErrors.error} wording="Add New Image" 
                    sendData={getData} 
                />
                {button}
            </div>
            <div className={classes.henrysImagesContainer}>
                {henrysRentalImages.images.map((image, i) =>
                <div className={classes.imageContainer} key={i}>
                    <ImCross onClick={confirmDeleteHandler.bind(this, image.id)} className={classes.removeImage + " delete"}/>
                    <img src={CONST.BASE_URL+"/storage/images/henrys-images/" + image.id + ".webp"} className={classes.image} alt="uploaded hernys image" />
                </div>
                )}
            </div>
        </div>
    )
}