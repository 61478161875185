import React from 'react';
import styles from './BogeEntryComment.module.css';
import { GiThumbUp, GiThumbDown } from "react-icons/gi";
import Moment from 'react-moment';
import CONST from '../../constants/constants';
import { Link } from 'react-router-dom';
import { AiOutlineDelete } from "react-icons/ai";

const bogeEntryComment = (props) => {

    let deleteButton = "";

    if(props.loggedInUserId === props.userId) {
        deleteButton =  <div className={styles.deleteIcon} onClick={props.deleteComment}>
                            <AiOutlineDelete />
                        </div>
    }

    let thumbs = <div className={styles.singleCommentInteractionContainer}>
                        <Link to={'/profile' + props.userId} className={styles.posterInfo}>
                            <img src={CONST.BASE_URL + "/storage/images/avatar-images/" + props.avatar} alt="" />
                            <h6 className={styles.singleCommentMaker}>{props.poster} @ <Moment date={props.posted} format="lll"></Moment></h6>
                        </Link>
                        <div onClick={() => props.clicked(props.id, "like")} className={styles.singleCommentInteraction}>
                            <GiThumbUp />
                            <h6>( {props.likes.length} )</h6>
                        </div>
                        <div onClick={() => props.clicked(props.id, "dislike")} className={styles.singleCommentInteraction}>
                            <GiThumbDown />
                        <h6>( {props.dislikes.length} )</h6>
                        </div>        
                    </div>                  
    if(JSON.parse(localStorage.getItem("user"))) {
        if(props.likes.some(like => like.user === JSON.parse(localStorage.getItem("user")).id)) {
            thumbs = <div className={styles.singleCommentInteractionContainer}>
                            <Link to={'/profile' + props.userId} className={styles.posterInfo}>
                                <img src={CONST.BASE_URL + "/storage/images/avatar-images/" + props.avatar} alt="" />
                                <h6 className={styles.singleCommentMaker}>{props.poster} @ <Moment date={props.posted} format="lll"></Moment></h6>
                            </Link>
                            <div onClick={() => props.removeLikeOrDislike(props.id, "unlike")} className={styles.singleCommentInteractionLiked}>
                                <GiThumbUp />
                                <h6>( {props.likes.length} )</h6>
                            </div>
                            <div className={styles.singleCommentInteractionGreyed}>
                            <GiThumbDown />
                                <h6>( {props.dislikes.length} )</h6>
                            </div>       
                        </div>   
        } else if(props.dislikes.some(dislike => dislike.user === JSON.parse(localStorage.getItem("user")).id)) {
            thumbs =    <div className={styles.singleCommentInteractionContainer}>
                            <Link to={'/profile' + props.userId} className={styles.posterInfo}>
                                <img src={CONST.BASE_URL + "/storage/images/avatar-images/" + props.avatar} alt="" />
                                <h6 className={styles.singleCommentMaker}>{props.poster} @ <Moment date={props.posted} format="lll"></Moment></h6>
                            </Link>
                            <div  className={styles.singleCommentInteractionGreyed}>
                                <GiThumbUp />
                                <h6>( {props.likes.length} )</h6>
                            </div>
                            <div onClick={() => props.removeLikeOrDislike(props.id, "undislike")} className={styles.singleCommentInteractionDisliked}>
                                <GiThumbDown />
                            <h6>( {props.dislikes.length} )</h6>
                        </div>        
                     </div>      
         }
    }

    return(
        <div className={styles.singleCommentContainer}>
            <div className={styles.commentContainer}>
                <p className={styles.singleComment}>{props.comment}</p>
                {deleteButton}
            </div>
            {thumbs}
        </div>
    )
}

export default bogeEntryComment