import Axios from 'axios';
import React, { Component } from 'react';
import styles from './Profile.module.css';
import CONST from '../../constants/constants';
import { Link, withRouter } from 'react-router-dom';
import GoBack from '../../components/Ui/GoBack/GoBack';

class Profile extends Component {

    state = {
        id: "",
        name: "",
        avatar: "",
        votes: "",
        bogeEntries: "",
    }

    componentDidMount(){
        Axios.get(CONST.BASE_URL+'/api/profile/'+this.props.match.params.id).then(response => {
            this.setState({
                id: response.data.id,
                name: response.data.name,
                avatar: "url(" + CONST.BASE_URL + "/storage/images/avatar-images/" + response.data.avatar + ")",
                votes: response.data.votes,
                bogeEntries: response.data.entries
            })
        })
    }

    render(){
        
        return(
            <div className={styles.profileContainer}>
                <GoBack back={() => this.props.history.goBack()} />
                <div style={{  
                backgroundImage: this.state.avatar
                }}
                className={styles.Profile}>
                <div className={styles.userProfileInfo}>
                    <p>{this.state.name}</p>
                    <button className='main-button'><Link to={"/user-boge-entries/" + this.state.id + "/entries"}>Boge Entries: {this.state.bogeEntries}</Link></button>
                    <p>Boge Entry Votes: {this.state.votes}</p>
                </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Profile)