import Axios from 'axios';
import React, { Component } from 'react';
import classes from './NotVotedBogeEntries.module.css';
import BogeEntrySnippet from '../../components/BogeEntrySnippet/BogeEntrySnippet';
import CONST from '../../constants/constants';
import GoBack from '../../components/Ui/GoBack/GoBack';

class NotVotedBogeEntries extends Component {
    
    state = {
        bogeEntries: []
    }

    componentDidMount() {
        Axios.defaults.withCredentials = true;
        Axios.get(CONST.BASE_URL + '/api/not-voted-boge-entries').then(response => {
            this.setState({bogeEntries: response.data});
        })
    };

    render() {

        let message = this.state.bogeEntries.length > 0 
                    ? <h4>Boge Entries needing your vote ({this.state.bogeEntries.length})</h4>
                    : <h4>No new Boge Entries to vote on</h4>

        return(
            <div className={classes.NotVotedBogeEntries}>
                <GoBack back={() => this.props.history.goBack()} />
                {message}
                {this.state.bogeEntries.map(bogeEntries =>
                    <BogeEntrySnippet
                        error={bogeEntries.grammar_error}
                        intention={bogeEntries.grammar_intention}
                        level={bogeEntries.error_level}
                        maker={bogeEntries.error_maker}
                        votes={bogeEntries.boge_entry_votes.length}
                        id={bogeEntries.id}
                        key={bogeEntries.id}
                    />
                )}
            </div>
        )
    }
}

export default NotVotedBogeEntries