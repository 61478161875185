import React from 'react';
import styles from './BogeEntryErrorLevel.module.css';

const bogeEntryErrorLevel = (props) => {
    let styleAdjust = styles.levelContainer;
    if(props.singlePage) {
        styleAdjust = styles.singlePageLevelContainer;
    }

    let colourAdjust = styles.level;

    if(props.error_level > 8.9) {
        colourAdjust = styles.bronze;
    }

    if(props.error_level > 9.3) {
        colourAdjust = styles.silver;
    }

    if(props.error_level > 9.6) {
        colourAdjust = styles.gold;
    }

    return(
        <div className={styleAdjust}>
            <h1 className={colourAdjust}>{props.error_level}</h1>
            <h5 className={styles.votes}>Votes: {props.votes}</h5>
        </div>
    )
}

export default bogeEntryErrorLevel