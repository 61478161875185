import React from 'react';
import classes from './Toolbar.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';
import Logo from './Logo/Logo';
import DrawerToggle from './DrawerToggle/DrawerToggle';

const toolbar = (props) => (
    <div className={classes.Toolbar}>
        <DrawerToggle menuIcon={props.show} clicked={props.drawToggleClicked}/>
        <div className={classes.largerScreensOnly}>
            <NavigationItems sideDrawerVisible={props.sideDrawer}/>
        </div>
        <Logo home={true} />
    </div>
)

export default toolbar
