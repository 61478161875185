import React from 'react';
import classes from './LoadingScreen.module.css';

const loadingScreen = (props) => {
    
        let henrysImage = "";
        let loadingImage = classes.messageContainer;
        let message = "";
        let standardLoading =   <div className={loadingImage}>
                                    <p className={message}>{props.message}</p>
                                    {henrysImage}
                                </div>

        if(props.henrysImage){
            standardLoading =   <div className={classes.henrysMessageContainer}>
                                    <img className={classes.henrysImage} src={props.henrysImage} />
                                    <p className={classes.message}>{props.message}</p>
                                </div>
        }

        if(props.henrysImageModal){
            standardLoading =   <div className={classes.loader}>
                                </div>
        }

        return(
            <div className={classes.LoadingScreen}>
                {standardLoading}
            </div>
        )
    }


export default loadingScreen