import React from 'react';
import classes from './DrawerToggle.module.css';
import { TiThMenuOutline, TiTimesOutline } from "react-icons/ti";


const drawerToggle = (props) => {

    let shownIcon = null;

    if(props.menuIcon) {
        shownIcon = <TiThMenuOutline />
    } else {
        shownIcon = <TiTimesOutline />
    }
    
    return(
        <div className={classes.DrawerToggle} onClick={props.clicked}>
            {shownIcon}
        </div>
    )
};

export default drawerToggle