import React from 'react';
import { Link } from 'react-router-dom';
import classes from './NavigationItem.module.css';
import FUNCTIONS from '../../../../functions/functions';

const navigationItem = (props) => {
    let type = props.navClicked
    if(props.reset){
        type = FUNCTIONS.handleStoringScrollPosition.bind(this, "reset")        
    }
    return(
        <div className={classes.NavigationItem}>
            <Link onClick={type} to={props.link}>{props.linkName}</Link>
        </div>
    )
}

export default navigationItem
