import React from 'react';
import styles from './ConfirmDelete.module.css';
import Backdrop from '../../Ux/Backdrop/Backdrop';
import Aux from '../../../hoc/Auxilary/Auxilary';
import Logo from '../Navigation/Toolbar/Logo/Logo';

const confirmDelete = (props) => {

    return(
        <Aux>
            <Backdrop full={true} show="yes" clicked={props.clicked}/>
            <div className={styles.confirmDeleteContainer}>
            <div className={styles.Logo}>
                    <Logo loading={true}/>
            </div>
            <div className={styles.ConfirmDelete}>
                <h1>ARE YOU SURE?</h1>
                <h4>This cannot be reversed!</h4>
                <div className={styles.choice}>
                    <button className={'main-button ' + styles.yes} onClick={props.delete}>Yes</button>
                    <button className={'main-button ' + styles.no} onClick={props.clicked}>No</button>
                </div>
            </div>
            </div>
        </Aux>
    )
}

export default confirmDelete