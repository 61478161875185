import Axios from 'axios';
import React, { Component } from 'react';
import classes from './UserBogeEntries.module.css';
import BogeEntrySnippet from '../../components/BogeEntrySnippet/BogeEntrySnippet';
import CONST from '../../constants/constants';
import GoBack from '../../components/Ui/GoBack/GoBack';
import FUNCTIONS from '../../functions/functions';

class UserBogeEntries extends Component {
    
    state = {
        bogeEntries: [],
        userName: ""
    }

    componentDidMount() {
        Axios.get(CONST.BASE_URL + '/api/user-boge-entries/' + this.props.match.params.id + "/" + this.props.match.params.type).then(response => {
            this.setState({
                bogeEntries: response.data.bogeEntries,
                userName: response.data.userName
            }, () => {
                FUNCTIONS.handleOldScrollPosition();
              })
        })
    };
    
    render() {

        let bogeEntriesMessage = "Boge Entries Posted by " + this.state.userName;

        if(this.props.match.params.type === "culprits"){
            bogeEntriesMessage = "Boge Entries made by " + this.state.userName;
        }

        return(
            <div className={classes.Home}>
                <GoBack back={() => this.props.history.goBack()} />
                <h4>{bogeEntriesMessage}</h4>
                {this.state.bogeEntries.map(bogeEntries =>
                    <BogeEntrySnippet
                        error={bogeEntries.grammar_error}
                        intention={bogeEntries.grammar_intention}
                        level={bogeEntries.error_level}
                        maker={bogeEntries.error_maker}
                        votes={bogeEntries.boge_entry_votes.length}
                        id={bogeEntries.id}
                        key={bogeEntries.id}
                    />
                )}
            </div>
        )
    }
}

export default UserBogeEntries