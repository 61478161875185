import React, { useState } from 'react';
import classes from './HenryRentalAddCategory.module.css';
import Axios from 'axios';
import CONST from '../../../constants/constants';
import FUNCTIONS from '../../../functions/functions';
import FlashMessage from '../../../components/Ui/FlashMessage/FlashMessage';
import GoBack from '../../../components/Ui/GoBack/GoBack';

export default function HenryRentalAddCategory(props){
    
    const [henryRentalAddCategory, setHenryRentalAddCategory] = useState({
        categoryName: "",
        categoryNameError: "",
        categoryNameSuccessMessage: ""
    });

    const changeHandler = (e) => setHenryRentalAddCategory(
        (prevState) => (
            { 
                ...prevState, [e.target.name]: e.target.value
            }
        ) 
    );

    const submitNewCategoryHandler = function(event){
        event.preventDefault();

            // Clear Error Message before new category added Message
            setHenryRentalAddCategory({
                categoryNameSuccessMessage: ""
            })

            if(!FUNCTIONS.checkAllowedTextInput(henryRentalAddCategory.categoryName, "Category")){                
                Axios.defaults.withCredentials = true;
                Axios.post(CONST.BASE_URL + '/api/henrys-rental-add-category', {
                    categoryName: henryRentalAddCategory.categoryName
                }).then(response =>{
                    console.log(response);
                    
                    setHenryRentalAddCategory({
                        categoryNameSuccessMessage: response.data,
                        categoryName: "",
                        categoryNameError: ""
                    })
                })
            } else {
                setHenryRentalAddCategory({
                    categoryNameError: FUNCTIONS.checkAllowedTextInput(henryRentalAddCategory.categoryName, "Category"),
                    categoryName: "",
                })
            }
    }

    let successMessage = "";

    if(henryRentalAddCategory.categoryNameSuccessMessage){
        successMessage = <FlashMessage message={henryRentalAddCategory.categoryNameSuccessMessage} duration={3000}/>;
    }

    return(
        <div className={classes.HenryRentalAddCategory}>
            <form className={classes.henryRentalAddCategoryForm}>
            <GoBack back={() => props.history.goBack()} />
                <h2 className={classes.henryRentalAddCategoryFormTitle}>Add New Henry's Rental Category</h2>
                <input
                    type="text"
                    name="categoryName"
                    placeholder="Add Category Here"
                    autoComplete="off"
                    value={henryRentalAddCategory.categoryName || ''}
                    onChange={changeHandler}
                />
                {henryRentalAddCategory.categoryNameError}
                <button className="main-button" onClick={submitNewCategoryHandler}>
                    Submit Category
                </button>
            {successMessage}
            </form>
        </div>
    )
}