import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Logo.module.css';
import logoImage from './Logo.png';
import FUNCTIONS from '../../../../../functions/functions';

const logo = (props) => {

    let logo = <img src={logoImage} alt=""/>
    
    if(props.home){
        logo =  <Link to="/">
                    <img src={logoImage} alt=""/>
                </Link>
        }
    
    let classes = styles.Logo;
    if(props.loading){
        classes = styles.Loading;
    }
    
    return (
        <div className={classes} onClick={FUNCTIONS.handleStoringScrollPosition.bind(this, "reset")}>
            {logo}
        </div>
    )
}

export default logo
