import { useEffect } from "react";
import classes from './EditActiveQuizQuestion.module.css';
import { FaPlus, FaMinus } from "react-icons/fa";
import axios from 'axios';
import CONST from '../../../../constants/constants';

export default function EditActiveQuizQuestion(props){

    const plusBonusQuestionsHandler = function(){
        axios.defaults.withCredentials = true;
        axios.post(CONST.BASE_URL + '/api/plus-question-bonus/' + props.questionId).then(response => {
            props.startingValues();
        })
    }

    const minusBonusQuestionsHandler = function(){
        axios.defaults.withCredentials = true;
        axios.post(CONST.BASE_URL + '/api/minus-question-bonus/' + props.questionId).then(response => {
            props.startingValues();
        })
    }

    let minus = <FaMinus className={classes.minusInactive}/>;

    if(props.bonusQuestions){
        minus = <FaMinus className={classes.minus} onClick={minusBonusQuestionsHandler}/>;
    }

    return(
        <div className={classes.editActiveQuizQuestion}>
            <h2>{props.i}</h2>
            <div className={classes.controlsContainer}>
                {minus}
                    <h2>{props.bonusQuestions}</h2>
                <FaPlus className={classes.plus} onClick={plusBonusQuestionsHandler}/>
            </div>
            
        </div>
    )
}