import { useState } from "react";
import classes from './SetQuizTeamName.module.css';
import axios from 'axios';
import CONST from '../../../constants/constants';
import { Redirect } from 'react-router-dom';

export default function SetQuizTeamName(){


    const [team, setTeam] = useState({
        name: ""
    });

    const [members, setMembers] = useState({
        names: ""
    });

    const [teamErrors, setTeamErrors] = useState({
        name: "",
        members: ""
    });

    const [quizRedirect, setQuizRedirect] = useState({
        url: ""
    });

    const changeTeamNameHandler = function(event){
        setTeam({
            name: event.target.value
        })
    }

    const changeTeamMembersHandler = function(event){
        setMembers({
            names: event.target.value
        })
    }

    const handleSubmitTeamName = function(event){
        event.preventDefault();

        let newTeamNameErrorMessage = "";
        let newTeamMembersErrorMessage = "";

        if(team.name === ""){
            newTeamNameErrorMessage = <h4 className="error">Team Name cannot be empty</h4>;
        }

        if (/[^a-zA-Z0-9 -,?!'`]/.test(team.name)) {
            newTeamNameErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(members.names === ""){
            newTeamMembersErrorMessage = <h4 className="error">Team Members cannot be empty</h4>;
        }

        if (/[^a-zA-Z0-9 -,?!'`]/.test(members.names)) {
            newTeamMembersErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(!newTeamNameErrorMessage && !newTeamMembersErrorMessage){
            axios.defaults.withCredentials = true;
            axios.post(CONST.BASE_URL + '/api/set-team-name', {newTeamName: team.name, newTeamMembers: members.names}).then(response => {
                setQuizRedirect({
                    url:    <Redirect to={{
                                pathname: "/quiz-landing-page"
                            }}                  
                        />
                })
            }).catch(function (error) {
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
            
              });
        } else {
            setTeamErrors({
                name: newTeamNameErrorMessage,
                members: newTeamMembersErrorMessage
            })
        }
      }

    return(
        <div className={classes.setQuizTeamName}>
            {quizRedirect.url}
            <h1>Set Team Name</h1>
            <input 
                className={classes.teamName}
                type="text"
                name="newTeamName"
                placeholder="Enter Team Name"
                autoComplete="off"
                value={team.name}
                onChange={changeTeamNameHandler}
            />
            {teamErrors.name}
            <input 
                className={classes.teamName}
                type="text"
                name="newTeamMembers"
                placeholder="Enter Team Members"
                autoComplete="off"
                value={team.members}
                onChange={changeTeamMembersHandler}
            />
            {teamErrors.members}
            <button className={"main-button "+classes.lockInButton} onClick={handleSubmitTeamName}>Submit Team Name</button>
        </div>
    )
}