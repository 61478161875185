import React from 'react';
import classes from './SearchBar.module.css';
import { BiSearchAlt } from "react-icons/bi";
import { RiSendPlane2Fill } from "react-icons/ri";


export default function SearchBar(props){

    let searchPlaceholder = "search the boge";

    let autoComplete = "";

    if(props.henrysRental) {
        searchPlaceholder = props.henrysRental;
    }

    return(
        <div className={classes.SearchBar}>
            <div className={classes.search}>
                <BiSearchAlt />
                <input 
                    className={classes.searchInput} 
                    placeholder={searchPlaceholder}
                    type="text"
                    name="searchInput"
                    autoComplete="off"
                    value={props.searchInput}
                    onChange={props.changeHandler}
                />
                <RiSendPlane2Fill onClick={props.submitSearchHandler}/>
            </div>
            {props.searchInputError}
            {autoComplete}
        </div>
    )
}