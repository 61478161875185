import { useState, useEffect } from 'react';
import classes from './EditActiveQuiz.module.css';
import LoadingScreen from '../../../components/Ux/LoadingScreen/LoadingScreen';
import axios from 'axios';
import CONST from '../../../constants/constants';
import EditActiveQuizQuestion from './EditActiveQuizQuestion/EditActiveQuizQuestion';
import ActiveQuizTeams from './ActiveQuizTeams/ActiveQuizTeams';

export default function EditActiveQuiz(props){

    const [questions, setQuestions] = useState({
        questions: [] 
    });

    const [quiz, setQuiz] = useState({
        id: "",
        name: "",
        numberOfQuestions: "",
        code: "",
        marking: false,
        completed: false
    });

    useEffect(() => {
        startingValues();
    }, []);

    const startingValues = function(){
        axios.defaults.withCredentials = true;
        axios.get(CONST.BASE_URL + '/api/get-single-active-quiz-admin/' + props.match.params.id).then(response => {
            setQuiz({
                id: response.data.singleActiveQuiz.id,
                name: response.data.singleActiveQuiz.name,
                numberOfQuestions: response.data.singleActiveQuiz.quiz_questions.length,
                code: response.data.singleActiveQuiz.quiz_code,
                marking: response.data.singleActiveQuiz.marking,
                completed: response.data.singleActiveQuiz.completed
            })
            setQuestions({
                questions: response.data.singleActiveQuiz.quiz_questions
            })
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    let activeQuizTeams = "";

    if (quiz.id) {
        activeQuizTeams = <ActiveQuizTeams quizId={quiz.id} completed={quiz.completed} marking={quiz.marking} startingValues={startingValues}/>;
    }

    return(
        <div className={classes.EditActiveQuiz}>
            <h1>{quiz.name}</h1>
            {activeQuizTeams}
            <div className={classes.quizQuestionContainer}>
                <h2>No. of Questions: {quiz.numberOfQuestions}</h2>
                <h2>Code: {quiz.code}</h2>
            </div>
            <div className={classes.quizQuestionContainer}>
                <h2>Question No.</h2>
                <h2>Bonus</h2>
            </div>
            {questions.questions.map((question, i) => 
                <EditActiveQuizQuestion 
                    key={i} 
                    i={i + 1} 
                    questionId={question.id}
                    bonusQuestions={question.bonus_questions} 
                    startingValues={startingValues}
                />
            )}
        </div>
    )
}