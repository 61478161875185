import React, { Component } from 'react';
import styles from './Layout.module.css';
import Toolbar from '../../components/Ui/Navigation/Toolbar/Toolbar';
import Login from '../../components/authentication/Login/Login';
import Register from '../../components/authentication/Register/Register';
import Home from '../../containers/Home/Home';
import NewBogeEntry from '../../containers/NewBogeEntry/NewBogeEntry';
import EditBogeEntry from '../../containers/EditBogeEntry/EditBogeEntry';
import BogeEntrySingle from '../../containers/BogeEntrySingle/BogeEntrySingle';
import MyProfile from '../../containers/MyProfile/MyProfile';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../../components/security/ProtectedRoute/ProtectedRoute';
import SideDrawer from '../../components/Ui/Navigation/SideDrawer/SideDrawer';
import Profile from '../../containers/Profile/Profile';
import EmailVerification from '../../components/authentication/EmailVerfication/EmailVerification';
import PasswordResetEmail from '../../components/authentication/PasswordResetEmail/PasswordResetEmail';
import SetNewPassword from '../../components/authentication/SetNewPassword/SetNewPassword';
import UserBogeEntries from '../../containers/UserBogeEntries/UserBogeEntries';
import NotVotedBogeEntries from '../../containers/NotVotedBogeEntries/NotVotedBogeEntries';
import SearchedBogeEntries from '../../containers/SearchedBogeEntries/SearchedBogeEntries';
import HenrysRental from '../../containers/HenrysRental/HenrysRental';
// Henry's Rental Components
import HenrysRentalAdd from '../../containers/HenrysRental/HenrysRentalAdd/HenrysRentalAdd';
import HenryRentalAddCategory from '../../containers/HenrysRental/HenryRentalAddCategory/HenryRentalAddCategory';
import HenrysRentalAddImages from '../../containers/HenrysRental/HenrysRentalAddImages/HenrysRentalAddImages';
// Quiz Components
import QuizLandingPage from '../../containers/Quiz/QuizLandingPage/QuizLandingPage';
import EditActiveQuiz from '../../containers/Quiz/EditActiveQuiz/EditActiveQuiz';
import CurrentlyPlayingQuiz from '../../containers/Quiz/CurrentlyPlayingQuiz/CurrentlyPlayingQuiz';
import SetQuizTeamName from '../../containers/Quiz/SetQuizTeamName/SetQuizTeamName';
import TeamsToMarkWaitingRoom from '../../containers/Quiz/TeamsToMarkWaitingRoom/TeamsToMarkWaitingRoom';
import AnswersToMark from '../../containers/Quiz/AnswersToMark/AnswersToMark';
import ResultsWaitingRoom from '../../containers/Quiz/ResultsWaitingRoom/ResultsWaitingRoom';
import CompletedQuiz from '../../containers/Quiz/CompletedQuiz/CompletedQuiz';

class Layout extends Component {

    state = {
        showSideDrawer: false,
        hamburgerMenuShown: true, 
    }
    
    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false})
    }
    
    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return {
                showSideDrawer: !prevState.showSideDrawer,
                hamburgerMenuShown: !prevState.hamburgerMenuShown, 
            };
        });
    }

    render(){

        let isAuthenticated = false;
        let admin = false

        if(JSON.parse(localStorage.getItem("user"))){
            isAuthenticated = true;
            if(JSON.parse(localStorage.getItem("user")).admin){
                admin = true
            }
        }
       
        return(
            <div className={styles.Layout}>
                <Toolbar show={this.state.hamburgerMenuShown} drawToggleClicked={this.sideDrawerToggleHandler} sideDrawer={this.state.hamburgerMenuShown}/>
                <SideDrawer open={this.state.showSideDrawer} drawToggleClicked={this.sideDrawerToggleHandler} />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <ProtectedRoute path="/new-boge-entry" exact component={NewBogeEntry} auth={isAuthenticated} />
                    <ProtectedRoute path="/edit-boge-entry:id" exact component={EditBogeEntry} auth={isAuthenticated} />
                    <ProtectedRoute path="/my-profile" exact component={MyProfile} auth={isAuthenticated} />
                    <ProtectedRoute path="/not-voted-boge-entries" exact component={NotVotedBogeEntries} auth={isAuthenticated} />
                    <ProtectedRoute path="/quiz-landing-page" exact component={QuizLandingPage} auth={isAuthenticated} />
                    <ProtectedRoute path="/edit-active-quiz/:id" exact component={EditActiveQuiz} auth={isAuthenticated} />
                    <ProtectedRoute path="/currently-playing-quiz/:id" exact component={CurrentlyPlayingQuiz} auth={isAuthenticated} />
                    <ProtectedRoute path="/teams-to-mark-waiting-room/:id" exact component={TeamsToMarkWaitingRoom} auth={isAuthenticated} />
                    <ProtectedRoute path="/answers-to-mark/:id" exact component={AnswersToMark} auth={isAuthenticated} />
                    <ProtectedRoute path="/set-team-name" exact component={SetQuizTeamName} auth={isAuthenticated} />
                    <ProtectedRoute path="/results-waiting-room/:id" exact component={ResultsWaitingRoom} auth={isAuthenticated} />
                    <ProtectedRoute path="/completed-quiz/:id" exact component={CompletedQuiz} auth={isAuthenticated} />
                    <ProtectedRoute path="/henrys-rental" exact component={HenrysRental} auth={isAuthenticated} />
                    <ProtectedRoute path="/henrys-rental-add" exact component={HenrysRentalAdd} auth={isAuthenticated} />
                    <ProtectedRoute path="/henrys-rental-add-category" exact component={HenryRentalAddCategory} auth={isAuthenticated} />
                    <ProtectedRoute path="/henrys-rental-add-images" exact component={HenrysRentalAddImages} auth={isAuthenticated} />
                    <Route path="/user-boge-entries/:id/:type" exact component={UserBogeEntries} />
                    <Route path="/user-boge-entries/:search" exact component={SearchedBogeEntries} />
                    <Route path="/email-verification" exact component={EmailVerification} />
                    <Route path="/boge-entry-single:id" exact component={BogeEntrySingle}/>
                    <Route path="/profile:id" exact component={Profile}/>
                    <Route path="/sign-up" exact component={Register}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/forgot-password-email" exact component={PasswordResetEmail}/>
                    <Route path="/snp" exact component={SetNewPassword}/>
                </Switch>
            </div>
        )
    }
}

export default Layout