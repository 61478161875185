import Axios from 'axios';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import classes from './NewBogeEntry.module.css';
import BogeEntryForm from '../../components/BogeEntryForm/BogeEntryForm';
import CONST from '../../constants/constants';
import LoadingScreen from '../../components/Ux/LoadingScreen/LoadingScreen';

class NewBogeEntry extends Component {

    state = {
            error: "",
            intention: "",
            maker: "",
            context: "",
            knownCulprits: [],
            errorErrorMessage: "",
            intentionErrorMessage: "",
            makerErrorMessage: "",
            contextErrorMessage: "",
            redirect: false,
            loadingScreen: false,
            culpritId: ""
    }

    componentDidMount(){   
        Axios.defaults.withCredentials = true;
        Axios.get(CONST.BASE_URL + '/api/known-culprits').then(response => {
            this.setState({knownCulprits: response.data})
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    }

    getData = (val, id) => {
        this.setState({
            maker: val,
            culpritId: id
        })
    }

    changeHandler = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
            errorErrorMessage: "",
            intentionErrorMessage: "",
            makerErrorMessage: "",
            contextErrorMessage: ""
        });
    }

    submitHandler = (event) => {
        event.preventDefault();

        let errorErrorMessage = "";
        let intentionErrorMessage = "";
        let makerErrorMessage = "";
        let contextErrorMessage = "";
        
        if(this.state.error.trim === ""){
            errorErrorMessage = <h4 className="error">Boge entry cannot be empty</h4>;
        }

        if(this.state.error.length < 5){
            errorErrorMessage = <h4 className="error">Boge entry must longer 5 characters</h4>;
        }

        if (/[^a-zA-Z0-9 -,?!'`]/.test(this.state.error)) {
            errorErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(this.state.intention.trim === ""){
            intentionErrorMessage = <h4 className="error">Intention cannot be empty</h4>;
        }

        if (/[^a-zA-Z0-9 -,?!'`]/.test(this.state.intention)) {
            intentionErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(this.state.maker.trim === ""){
            makerErrorMessage = <h4 className="error">Culprit cannot be empty</h4>;
        }

        if (/[^a-zA-Z0-9 -]/.test(this.state.maker)) {
            makerErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if (/[^a-zA-Z0-9 -?,!'`]/.test(this.state.context)) {
            contextErrorMessage = <h4 className="error">Please enter only letters and numbers</h4>;
        }

        if(!errorErrorMessage && !intentionErrorMessage && !makerErrorMessage && !contextErrorMessage){
            this.setState({
                loadingScreen: <LoadingScreen message="Now creating your new Boge entry. Please Wait" />
            }) 
            Axios.defaults.withCredentials = true;
            Axios.post(CONST.BASE_URL + '/api/new-boge-entry', {
                error: this.state.error,
                intention: this.state.intention,
                context: this.state.context,
                maker: this.state.maker,
                poster: JSON.parse(localStorage.getItem("user")).id,
                error_maker_user: this.state.culpritId ? this.state.culpritId : ""
            }).then(response =>{
                this.setState({
                    loadingScreen: false,
                    redirect: <Redirect to={{
                        pathname: "/boge-entry-single"+ response.data.id,
                        state: { fromRedirect: "New Boge Entry successfully created" }
                        }}                  
                    />
                })
            })
        } else {
            this.setState({
                errorErrorMessage: errorErrorMessage,
                intentionErrorMessage: intentionErrorMessage,
                makerErrorMessage: makerErrorMessage,
                contextErrorMessage: contextErrorMessage
            })
        }
    }

    render() {
        let redirect = this.state.redirect;

        return(
            <div className={classes.NewBogeEntry}>
                {this.state.loadingScreen}
                {redirect}
                <BogeEntryForm
                    newBogeEntry="true"
                    error={this.state.error}
                    intention={this.state.intention}
                    context={this.state.context}
                    maker={this.state.maker}
                    knownCulprits={this.state.knownCulprits}
                    errorErrorMessage={this.state.errorErrorMessage}
                    intentionErrorMessage={this.state.intentionErrorMessage}
                    makerErrorMessage={this.state.makerErrorMessage}
                    contextErrorMessage={this.state.contextErrorMessage}
                    changeHandler={this.changeHandler}
                    submit={this.submitHandler}
                    sendData={this.getData}
                />
            </div>
        )
    }
}

export default NewBogeEntry
