import React from 'react';
import styles from './Backdrop.module.css';

const backdrop = (props) => {

    let backdrop = null;

    if(props.show){
        if(props.full){
            backdrop = <div className={styles.full} onClick={props.clicked}></div>;    
        } else {
            backdrop = <div className={styles.Backdrop} onClick={props.clicked}></div>;
        }
    }

    return(
        <div>
            {backdrop}
        </div>
    )
};

export default backdrop