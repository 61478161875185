let myFunctionsClass = {

    // Scroll Positions

    scrollToTop() {
        window.scrollTo(0, 0);
    },

    handleOldScrollPosition() {
        const scrollPosition = localStorage.getItem("scrollPosition");
        if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition));
            localStorage.removeItem("scrollPosition");
            localStorage.removeItem("tab")
        }
    },

    handleStoringScrollPosition(tab) {
    if (tab === "reset") {
        localStorage.removeItem("scrollPosition");
        localStorage.removeItem("tab");
        // window.location.reload(true);
    } else {
        localStorage.setItem("scrollPosition", window.pageYOffset);
        localStorage.setItem("tab", tab);
    }
    },

    // Site Form Error checking
    
    checkAllowedTextInput(input, type){
        let error = "";
        if(type){
            if(input === ""){
                error = <h4 className="error">{type} cannot be empty</h4>;
            } else if (/[^a-zA-Z0-9 ,?!':().£&-/"]/.test(input)) {
                error = <h4 className="error">Please enter only letters and numbers</h4>;
            }
            return error;
        } else if (/[^a-zA-Z0-9 ,?!':().£&-/"]/.test(input)) {
            error = <h4 className="error">Please enter only letters and numbers</h4>;
        }
        return error;
    },

    checkAllowedPriceInput(input){
        let error = "";
        if(input === ""){
            error = <h4 className="error">Price cannot be empty</h4>;
        } else if (/[^0-9.]/.test(input)) {
            error = <h4 className="error">Please enter only numbers and decimal point</h4>;
        }
        return error;
    },

    checkAllowedDurationInput(input){
        let error = "";
        if(input === ""){
            error = <h4 className="error">Duration cannot be empty</h4>;
        } else if (/[^0-9]/.test(input)) {
            error = <h4 className="error">Please enter only numbers</h4>;
        }
        return error;
    },

    checkAllowedSelectInput(input, type){
        let error = "";
        if(input === "select" || input === ""){
            error = <h4 className="error">Please choose a {type}!</h4>;
        }
        return error;
    },

    flashMessage(type, message){
        let flashMessage = ""; 
        if(type === "success"){
            <div className="load-msg">
                <h3 className="success">{message}</h3>
            </div>
        }
        
        return flashMessage
    }
}


export default myFunctionsClass